import { Tag } from '@arcault-ui/shared-components';


export const RFP_NON_RESPONSE = 'OPEN';
export const RFP_RESPONSE = 'rfp-response';
export const RFP_RESPONSE_ACCEPTED = 'rfp-response-accepted';
export const RFP_RESPONSE_REJECTED = 'rfp-response-rejected';

export const statusDisplay = (value) => {
    switch (value) {
        case RFP_NON_RESPONSE: 
          return '';
        case RFP_RESPONSE:
          return (<Tag label="Response Sent" variant="primary" />);
        case RFP_RESPONSE_ACCEPTED:
          return (<Tag label="Response Accepted" variant="primary" />);
        case RFP_RESPONSE_REJECTED:
          return (<Tag label="Response Rejected" variant="primary" />);      
      }
}

/*export const actionsProducer = (actions) => (<>
    {actions.filter(a => a.condition ? a.condition(row) : true)?.map((action, index) => (
        <IconButton
        key={index}
        className={action.iconClassName}
        aria-label={action.label}
        onClick={() => action.onClick(row)}
        >
        <Icon name={action.iconName} />
        </IconButton>
    ))}
</>);*/