import setupAxiosWithCredentials from "../utils/setupAxiosWithCredentials";
import { axiosWithCredentials } from "../api/axios";
import Base from './base';

const BASE_URL = 'engage/leads';

class Leads extends Base {
    async view(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.get(BASE_URL + '/view/' + id);
            return data;
        } finally {
            removeInterceptors();
        }
    }
}

export const leadsAPI = new Leads(BASE_URL);
