import React, { useState, useEffect } from 'react';
import { Button, FormInput, HStack, Loading, Text } from '@arcault-ui/shared-components';
import './Form.css'

const Form = ({ fields, getFunction, updateFunction, dataCallback }) => {
    const [data, setData] = useState({});
    const [saved, setSaved] = useState(false);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [hasEdits, setHasEdits] = useState(false);

    const fetchData = async () => {
        const response = await getFunction();
        setLoading(false);
        setData(response.data);
        if (dataCallback) dataCallback(response.data); // initially load data in parent component
    };

    useEffect(() => {
        if (getFunction) {
            fetchData();
        }
    }, [getFunction]);

    const handleChange = (e, id) => {
        const { value } = e.target;
        setData({ ...data, [id]: value });
        if (dataCallback) dataCallback({ ...data, [id]: value });
        if (setHasEdits) setHasEdits(true);
    };

    const groupFields = (fields) => {
        const grouped = [];
        let tempGroup = [];

        fields.forEach((field) => {
            if (field.fullWidth) {
                if (tempGroup.length > 0) {
                    grouped.push(tempGroup);
                    tempGroup = [];
                }
                grouped.push([field]);
            } else {
                tempGroup.push(field);
                if (tempGroup.length === 2) {
                    grouped.push(tempGroup);
                    tempGroup = [];
                }
            }
        });

        if (tempGroup.length > 0) {
            grouped.push(tempGroup);
        }

        return grouped;
    };

    const groupedFields = groupFields(fields);

    const onSaved = async () => {
        setSaving(true);
        if (updateFunction) await updateFunction(data)
        setSaved(true);
        setSaving(false);
        setHasEdits(false);
        setTimeout(() => setSaved(false), 3000);
    }

    return (
        <>
            {loading && <Loading />}
            {!loading && <>
                {groupedFields.map((group, index) => (
                <HStack key={index} spacing={4}>
                    {group.map((f) => (
                        <FormInput
                            key={f.id}
                            label={f.label}
                            value={data[f.id]}
                            type={f.type || 'text'}
                            onChange={(e) => handleChange(e, f.id)}
                            fullWidth={f.fullWidth}
                        />
                    ))}
                </HStack>
            ))}
            <div>
                <HStack className="form-status" variant="end">
                    <Text variant="caption">{saved ? 'Saved' : ' '}</Text>
                </HStack>
                <HStack variant="end">
                    <Button type="primary" disabled={!hasEdits || saving} onClick={onSaved}>
                        {saving ? 'Saving...' : 'Save'}
                    </Button>
                </HStack>

            </div>
            </>}
        </>
    );
};

export default Form;