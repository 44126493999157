import { useState, useEffect } from 'react';
import './App.css';

import CallCenter from './components/CallCenter'
import CRMLayout from './components/CRMLayout'
import Customers from './components/Customers'
import Customer from './components/Customer'
import Leads from './components/leads/Leads'
import Proposals from './components/Proposals'
import Proposal from './components/Proposal'
import Profile from './components/profile/Profile'
import CompanyProfile from './components/profile/CompanyProfile'
import IndividualLead from './components/leads/IndividualLead'



import NotFound from './components/NotFound'
import {Route, Routes } from 'react-router-dom';


import Terms from './components/Terms'
import Privacy from './components/Privacy'
import OAuthCallback from './components/OAuthCallback';
import Logout from './components/Logout'; // Import your logout component
import SignIn from './components/SignIn'; // Import your logout component
import SignUp from './components/SignUp'; // Import your logout component

import RequireAuth from './components/RequireAuth';
import ForgotPassword from './components/ForgotPassword'

import ConfirmEmail from './components/ConfirmEmail'
import { MessageProvider } from './context/MessageContext'; 
import Creds from './components/Creds';

function App() {

  return (

    <MessageProvider>
    <Routes>
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/oauth2/idpresponse" element={<OAuthCallback />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/confirmemail" element={<ConfirmEmail />} />
      {/* authenticated routes */}
      <Route element={<RequireAuth />}>
        <Route element={<CRMLayout/>} >
          <Route path="/" element={<Leads />}/>
          <Route path="/creds" element={<Creds />}/>
          <Route path="/connect" element={<CallCenter />}/>
          <Route path="/profile" element={<Profile />}/>
          <Route path="/company" element={<CompanyProfile />}/>
          <Route path="/customers" element={<Customers />}/>
          <Route path="/customers/:id" element={<Customers />}/>
          <Route path="/leads" element={<Leads />}/>
          <Route path="/leads/:id" element={<Leads />} />
          <Route path="/proposals" element={<Proposals />}/>
          <Route path="/proposals/:proposalsId" element={<Proposal />}/>
        </Route>
      </Route>
        {/* catch all */} 
      <Route path="*" element={<NotFound/>}/>
      </Routes>
      </MessageProvider>
  );
}

export default App;