import setupAxiosWithCredentials from "../utils/setupAxiosWithCredentials";
import { axiosWithCredentials } from "../api/axios";
import Base from './base';

const BASE_URL = 'users/profile';
class Profile extends Base {
    update = async (data) => {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const response = await axiosWithCredentials.put(this.base_url, data);
            return response;
        } finally {
            removeInterceptors();
        }
    }
}

const COMPANY_BASE_URL = 'users/profile/business';
class CompanyProfile extends Base {
    update = async (data) => {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const response = await axiosWithCredentials.put('users/business', data);
            return response;
        } finally {
            removeInterceptors();
        }
    }
}

export const userProfileAPI = new Profile(BASE_URL);
export const companyProfileAPI = new CompanyProfile(COMPANY_BASE_URL);
