import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Box, CssBaseline, AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, Avatar } from '@mui/material';
import { Menu as MenuIcon, Home as HomeIcon, Settings as SettingsIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import CustomerHeader from './CustomerHeader'
import InteractionButtons from './InteractionButtons'
import ContactInfo from './ContactInfo'
import TagsSection from './TagsSection'
import LeadInfo from './LeadInfo'
import ActivityTabs from './ActivityTabs'
import ActivityStream from './ActivityStream'
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';
import DigitalVault from './DigitalVault';


const customerData = {
  name: 'Jon Ferrara',
  title: 'CEO/Founder at Nimble Inc.',
  profilePic: '/path/to/profile-pic.jpg', // Replace with actual path
  socialLinks: { /* ... social links ... */ },
  contactInfo: {
    email: 'jon@nimbleinc.com',
    phone: '123-456-7890',
    address: 'Santa Monica, United States',
  },
  tags: ['Microsoft Partners - Leads'],
  // ... other customer data
};



function Customer(props) {
  
const axiosWithCredentials = useAxiosWithCredentials();
  // Assume customerData is fetched and provided as props or via context
  const { contactInfo, tags } = customerData;
  const [currentTab, setCurrentTab] = useState('all');
  const { contactId } = useParams(); // This is how you access the contactId from the URL

  useEffect(() => {
      // Here you can fetch the customer data using the contactId
      // For example:
      console.log(contactId); // Do something with contactId, like fetching customer data
    }, [contactId]); // This effect depends on contactId, it will rerun when contactId changes

  const handleTabChange = (newValue) => {
    setCurrentTab(newValue);
    // Add additional logic for loading the respective activities if needed
  };
  
  
  return (
    <Grid container spacing={2} sx={{mt:10}}>
      <Grid item xs={12} md={4}>
        <CustomerHeader contactId={contactId} />
        <InteractionButtons />
        <ContactInfo contactId={contactId} />
        <TagsSection contactId={contactId} />
        <LeadInfo leadDetails={{
  lastContacted: 'an hour ago by me',
  details: {
    'Type': 'Prospect',
    'Source': 'Trade Shows',
    'Status': 'Qualified'
  },
  activeDeals: [
    { name: 'Influencer Marketing CRM', value: '$25.0k', percentage: 60 }
  ]
}}  />
      </Grid>
      <Grid item xs={12} md={8}>
        <div>
          <ActivityTabs onTabChange={handleTabChange} tabValue={currentTab} />
          {/* Content that changes with the tab selection */}
        </div>
        <ActivityStream activities={customerData.activities} />
        {currentTab === 'files' && (
        <DigitalVault
          category={`crm_${contactInfo.userId}`}
    />
        )}
      </Grid>
    </Grid>
    
  );
}


export default Customer;