import React, { useState, useEffect } from 'react';
import { Card, HStack, Icon, Stack, Tag, Text, Table } from '@arcault-ui/shared-components';
import IndividualLead from './IndividualLead';
import { RFP_RESPONSE_ACCEPTED, RFP_RESPONSE_REJECTED, RFP_NON_RESPONSE, RFP_RESPONSE } from "./constants";
import CardView from './LeadsCardView';
import { leadsAPI } from '../../actions/leads';
import { proposalsAPI } from '../../actions/proposals';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useParams } from 'react-router-dom';


const Leads = () => {
  const [displayedLeads, setDisplayedLeads] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const { id } = useParams();

  const fetchLeads = async () => {
    try {
      const data = await leadsAPI.get();
      return data.data;
    } catch (error) {
      console.error("Error loading files:", error);
    }
  };

  const fetchProposals = async () => {
    try {
      const data = await proposalsAPI.get();
      return data.data;
    } catch (error) {
      console.error("Error loading files:", error);
    }
  };
  
  useEffect(() => {
    fetchLeads()
      .then(leads => {
        setDisplayedLeads([...(leads || []).map(lead => ({...lead, ...lead.rfp, service: 'rfp'}))]);
      });

    fetchProposals()
      .then(proposals => {
        console.log('proposals', proposals);
      })
  }, []);

  return (
    <Card className="card-no-shadow leads-dashboard">
      <Text variant="h1">Leads</Text>
      {!id && (
        <Table CardView={CardView} rows={displayedLeads} columns={[
            { id: 'title', label: 'Title' },
            { id: 'geography', label: 'Location' },
            { id: 'timeline', label: 'Timeline' },
            { id: 'status', label: 'Status' },
            { id: 'created', label: 'Dated Submitted' },
          ]} displayValue={
            (value, column) => {
              console.log('value', value, column)
              if (column.id === 'status') {
                switch (value) {
                  case RFP_NON_RESPONSE: 
                    return '';
                  case RFP_RESPONSE:
                    return (<Tag label="Response Sent" variant="primary" />);
                  case RFP_RESPONSE_ACCEPTED:
                    return (<Tag label="Response Accepted" variant="primary" />);
                  case RFP_RESPONSE_REJECTED:
                    return (<Tag label="Response Rejected" variant="primary" />);      
                }
              }
              return value;
            }
          }
          onClick={(id) => {
            navigate(`/leads/${id}`); // Navigate to the routed individual lead
          }}
        />
      )}
      {id && (
        <IndividualLead activeRFPId={id} />
      )}
    </Card>
    
  );
}


export default Leads;
