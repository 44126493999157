import setupAxiosWithCredentials from "../utils/setupAxiosWithCredentials";
import { axiosWithCredentials } from "../api/axios";
import Base from './base';

const BASE_URL = 'engage/proposal';

class Proposals extends Base {
    async draft(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.get(BASE_URL + '/draft/' + id);
            return data;
        } finally {
            removeInterceptors();
        }
    }

    async send(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const response = await axiosWithCredentials.post('engage/send/proposal/' + id);
            return response;
        } finally {
            removeInterceptors();
        }
    }
}

export const proposalsAPI = new Proposals(BASE_URL);
