import { Card, Stack, Text } from '@arcault-ui/shared-components';

import './Leads.css';
import { statusDisplay } from './constants';

/*{ id: 'summary', label: 'Summary' },
{ id: 'location', label: 'Location' },
{ id: 'status', label: 'Status' },
{ id: 'postedOn', label: 'Dated Submitted' },
]} displayValue={
(value, column) => {
  console.log('value', value, column)
  if (column.id === 'status') {
    
  }
  return value;
}*/



const LeadsCardView = ({ data, onClick }) => (
    <Card onClick={() => onClick(data.id)} className="lead-card" title={data.title} footer={(<div>Actions</div>)}>
        <Stack className="lead-card-inner" variant="stretch">
            <Text type="body-large">{data.summary}</Text>
            <Text type="body">{data.location}</Text>
            <Text type="caption">{statusDisplay(data.status)}</Text>
        </Stack>
    </Card>
)

export default LeadsCardView;