import {useLocation, Navigate, Outlet} from "react-router-dom";
import {useAuthenticator} from "@aws-amplify/ui-react"
 
export default function RequireAuth({children}){
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const location = useLocation();
  
  if (authStatus === 'configuring') {
    return 'Loading...';
  }

  if (authStatus !== 'authenticated') {
    return <Navigate to="/signin" state={{from: location}} replace />;
  }

  return <Outlet />;
}