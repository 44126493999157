import React, { useState, useEffect } from 'react';

import { Button, FormInput, HStack, Stack, Text, Loading } from '@arcault-ui/shared-components';
import { RFP_NON_RESPONSE } from './constants';
import { proposalsAPI } from '../../actions/proposals';

/*
pricing
: 
"Our pricing is competitive and based on the complexity of the estate plan required. We offer a flat fee for standard estate planning services, with additional fees for more complex needs."

scopeOfWork
: 
"Our estate planning services include the creation of wills, trusts, and powers of attorney, as well as guidance on tax implications and asset protection strategies. We work closely with clients to understand their specific needs and develop a customized estate plan that reflects their wishes and provides peace of mind. Our deliverables include a comprehensive estate plan document, ongoing support, and regular reviews to ensure the plan remains aligned with the client's goals."

summary
: 
"We are a dedicated estate planning firm with a commitment to providing personalized and comprehensive estate planning services. Our team of experienced professionals understands the complexities of estate planning and is dedicated to helping clients achieve their goals. We offer a range of services tailored to meet the unique needs of each client, ensuring that their assets are protected and their wishes are honored."
userId
: 
"7d051c56-9acf-4848-a620-65af18a38ef8"
*/

const ProposalForm = ({ setIsSent, proposal, setProposal }) => {
  const [isLoading, setLoading] = useState(false);

  const saveProposal = async () => {
    setLoading('Saving');
    await proposalsAPI.save(proposal);
    setLoading(false);
  }

  const sendProposal = async () => {
    setLoading('Sending');
    await saveProposal();
    await proposalsAPI.send(proposal.id);
    setIsSent(true);
    setLoading(false);
  }

  const handleOnChange = (id, e) => {
    setProposal({ ...proposal, [id]: e.target.value });
  }

  return (
    <Stack className="full-height-card">
      <Text variant="h2">Your Proposal</Text>
      {isLoading && <Loading label={isLoading} />}
      <div className="proposal-textarea">
        <FormInput label="Summary" value={proposal?.summary} onChange={(e) => handleOnChange('summary', e)} type="textarea" className="flex-one"/>
      </div>
      <div className="proposal-textarea">
        <FormInput label="Scope of Work" 
          value={proposal?.scopeOfWork} 
          onChange={(e) => handleOnChange('scopeOfWork', e)} 
          type="textarea" className="flex-one"/>
      </div>
      <div className="proposal-textarea">
        <FormInput label="Pricing"
          value={proposal?.pricing}
          onChange={(e) => handleOnChange('pricing', e)} 
          type="textarea" className="flex-one"/>
      </div>
      
      <HStack>
        <FormInput
          value={proposal?.timing} 
          onChange={(e) => handleOnChange('timing', e)} 
          type="text" label="Est. Timing"/>
      </HStack>
      <HStack variant="end">
        <Button type="outline" onClick={saveProposal}>Save</Button>
        <Button type="primary" onClick={sendProposal}>Send</Button>
      </HStack>
    </Stack>
  )
}

export default ProposalForm;