import React, { useState, useEffect } from 'react';
import { Stack, HStack, Text, Icon, Loading } from '@arcault-ui/shared-components';
import RFPSummary from './RFPSummary';
import ProposalForm from './ProposalForm';
import ProposalSummary from './ProposalSummary';
import { leadsAPI } from '../../actions/leads';
import { proposalsAPI } from '../../actions/proposals';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const IndividualLead = ({ activeRFPId }) => {
    const [lead, setLead] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [proposal, setProposal] = useState({});
    const [isSent, setIsSent] = useState(false);
      const navigate = useNavigate(); // Initialize useNavigate
  
    const getProposals = async () => {
      const data = await proposalsAPI.draft(lead.id)
      
    console.log('DATA From Draft', data.data)
      const { id } = data.data;
    
    console.log('ID From Draft', id)
    
      const savedData = await proposalsAPI.get(id)
      if (savedData.data && savedData.data[0]) {
        console.log('data', savedData)
        if (savedData.data[0].status === 'SENT') {
          setIsSent(true);
        }
        setProposal(savedData.data[0]);
      } else {
        setProposal(data.data);
      }
      setIsLoading(false);
    }
    
    const loadLead = async (id) => {
        setIsLoading(true);
        try {
            const data = await leadsAPI.view(id);
            setLead(data.data);
        } catch (error) {
            console.error("Error loading files:", error);
        }
        
    }

    useEffect(() => {
        if (lead?.id) {
            getProposals();
        }
    }, [lead])

    useEffect(() => {
        if (activeRFPId) {
            loadLead(activeRFPId);
        }
    }, [activeRFPId])

    return (
        <Stack className="leads-dashboard" variant="start">
            <Text variant="h2">
                <div onClick={() => navigate('/leads')}>
                <Icon name="arrow_back_ios" />
                </div>
                {lead.title}
            </Text>
            {isLoading && <Loading />}
            <HStack className="full-height-card leads-dashboard-container">
                <div className="proposal-half">
                    <RFPSummary rfp={lead} />
                </div>
                <div className="proposal-half your-proposal">
                {(!isSent) && <ProposalForm proposal={proposal} setIsSent={setIsSent} setProposal={setProposal}  lead={lead} />}
                {isSent && <ProposalSummary proposal={proposal} />}
                </div>
            </HStack>
        </Stack>
    );
};

export default IndividualLead;