import { useState, useEffect, useRef, useCallback } from 'react';
import useAxiosWithCredentials from './useAxiosWithCredentials';
import axios from 'axios';

const useDocuments = ({ initialDocuments, category, addCallBack }) => {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [sharedIds, setSharedIds] = useState([]);
  const [dragOver, setDragOver] = useState(false);
  const [fileShareStatus, setFileShareStatus] = useState({});

  const fileInputRef = useRef(null);
  
  const axiosWithCredentials = useRef(useAxiosWithCredentials()).current;

  const fetchDocuments = async () => {
    try {
        setIsLoading(true);
        let theseDocuments = initialDocuments
        if (!initialDocuments) {
          const docResponse = await axiosWithCredentials.get('files/v2/' + category + '/');
          theseDocuments = docResponse.data;
        }
        setDocuments(theseDocuments);
      
      // Find the file with fileName '.meta'
      const metadataFile = theseDocuments.find(file => file.fileName === '.meta');
            // If the file is found, set the sharedUsers state to its 'shared' property
      if (metadataFile && metadataFile.sharedWith) {
        setSharedIds(metadataFile.sharedWith);
      }
      
      // Update file share status based on sharedUsers
      const fileShareStatus = {};
      theseDocuments.forEach(doc => {
        fileShareStatus[doc.sortKey] = metadataFile && metadataFile.sharedWith ? metadataFile.sharedWith.includes(doc.fileName) : false;
      });
      setFileShareStatus(fileShareStatus);

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching theseDocuments:', error);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        handleUpload(files[i]);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const DropArea = ({ children, onClick }) => (
    <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={onClick || (() => {})}
        className={`dropzone ${dragOver ? 'dropzone-active' : ''} ${uploading ? 'dropzone-uploading' : ''}`}
    >
        {children}
    </div>
  );

  const handleUploadClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const handleUpload = async (file) => {
    try {
      setUploading(true);
      const response = await axiosWithCredentials.post('/files/v2/upload/' + category, {
        fileName: file.name,
        fileType: file.type,
      });

      const { uploadUrl, fileId } = response.data;

      console.log("Got Upload Request .... ",uploadUrl, fileId)
      const s3Response = await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      if (s3Response.status === 200) {
        await axiosWithCredentials.put(`/files/v2/fileStatus/${category}/${fileId}`, { status: 'uploaded' });
        console.log('added file to db', addCallBack, fileId);
        if (addCallBack) addCallBack(fileId)
        fetchDocuments();
        setUploading(false);
      } else {
        console.error('File upload failed', s3Response);
      }
    } catch (error) {
      
      console.error('Error during file upload', error);
    }
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
        const file = files[0];
        handleUpload(file);
    }
  }

  const UploadMechanism = ({ children }) => (
    <span data-testid="upload-mechanism" onClick={handleUploadClick}>
        {children}
        <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileSelect} />
    </span>
)

  // generated //


  const addDocument = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      await axiosWithCredentials.post('/api/documents', formData);
      fetchDocuments(); // Refresh documents after upload
    } catch (error) {
      console.error('Error uploading document:', error);
    }
  };

  const deleteDocument = async (fileId, category) => {
    try {
      const newDocuments = documents.filter(d => d.fileId !== fileId )
      setDocuments(newDocuments);

      const response = await axiosWithCredentials.delete(`/files/v2/${category}/${fileId}`);

      if (response.status === 200 || response.status === 201) {
        console.log('File delete successful');        
      } else {
        console.error('File delete failed');
      }
    } catch (error) {
      console.error('Error during file delete', error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return {
    documents,
    fetchDocuments,
    sharedIds,
    deleteDocument,
    UploadMechanism,
    uploading,
    fileShareStatus,
    DropArea,
    handleUploadClick,
  };
};

export default useDocuments;