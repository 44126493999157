const Theme = {
  "themeName": "default",
  "iconsSet": "v2",
  "cssVariables": {
    "--ctr-toolbox-scrollbar-left": "auto",
    "--ctr-toolbox-scrollbar-right": "0",
    "--ctr-toolbox-scroller-align-items": "flex-start",
    "--ctr-toolbox-item-align": "stretch",
    "--ctr-toolbox-margin-left-compact": "0px",
    "--ctr-toolbox-item-banner-icon-display": "none",
    "--ctr-toolbox-item-banner-beak-display": "block",
    "--ctr-toolbox-submenu-item-min-width": "calc(17 * 8px)",
    "--ctr-toolbox-group-header-margin-left": "0px",
    "--ctr-button-group-item-border-width": "0px",
    "--ctr-shadow-medium-blur": "8px",
    "--ctr-shadow-medium-offset-y": "2px",
    "--ctr-shadow-large-blur": "32px",
    "--ctr-shadow-large-offset-y": "16px",
    "--ctr-shadow-large-offset-x": "0px",
    "--ctr-shadow-large-spread": "0px",
    "--ctr-shadow-medium-offset-x": "0px",
    "--ctr-shadow-medium-spread": "0px",
    "--ctr-button-group-item-border-width-selected-disabled": "0px",
    "--ctr-shadow-small-offset-x": "0px",
    "--ctr-shadow-small-offset-y": "1px",
    "--ctr-shadow-small-blur": "2px",
    "--ctr-shadow-small-spread": "0px",
    "--ctr-shadow-x-large-offset-x": "0px",
    "--ctr-shadow-x-large-offset-y": "32px",
    "--ctr-shadow-x-large-blur": "64px",
    "--ctr-shadow-x-large-spread": "0px",
    "--ctr-property-grid-placeholder-text-max-width": "320px",
    "--ctr-surface-placeholder-padding-top": "0px",
    "--ctr-toolbox-group-header-margin-right": "0px",
    "--ctr-property-grid-placeholder-padding-bottom": "0px",
    "--ctr-editor-content-text-opacity-disabled": 0.25,
    "--ctr-editor-label-opacity-disabled": 0.25,
    "--ctr-editor-button-icon-opacity-disabled": 0.25,
    "--ctr-editor-button-icon-opacity-pressed": 0.5,
    "--ctr-actionbar-button-opacity-pressed": 0.5,
    "--ctr-actionbar-button-opacity-disabled": 0.25,
    "--ctr-actionbar-button-opacity-muted": 0.35,
    "--ctr-menu-item-opacity-disabled": 0.25,
    "--ctr-menu-toolbar-button-opacity-disabled": 0.25,
    "--ctr-menu-toolbar-button-opacity-pressed": 0.5,
    "--ctr-toolbox-item-text-opacity-pressed": 0.5,
    "--ctr-toolbox-item-icon-opacity-pressed": 0.35,
    "--ctr-toolbox-item-opacity-submenu": 1,
    "--ctr-toolbox-item-text-opacity-disabled": 0.25,
    "--ctr-page-navigator-button-opacity-pressed": 0.5,
    "--ctr-button-text-opacity-disabled": 0.25,
    "--ctr-notification-opacity": 0.75,
    "--ctr-notification-opacity-error": 1,
    "--ctr-toolbox-group-header-border-width-top": "0px",
    "--ctr-toolbox-group-header-border-width-left": "0px",
    "--ctr-toolbox-group-header-border-width-right": "0px",
    "--ctr-data-table-cell-drop-down-arrow-opacity": 0.5,
    "--ctr-data-table-row-padding-left": "0px",
    "--ctr-data-table-row-border-width-last-row": "0px",
    "--ctr-toolbox-separator-padding-right": "0px",
    "--ctr-survey-question-panel-toolbar-item-opacity-pressed": 0.5,
    "--ctr-survey-question-panel-toolbar-item-opacity-disabled": 0.25,
    "--ctr-survey-question-panel-toolbar-item-opacity-muted": 0.5,
    "--ctr-survey-question-panel-toolbar-item-padding-left-small": "0px",
    "--ctr-survey-question-panel-toolbar-item-padding-right-small": "0px",
    "--ctr-survey-question-panel-toolbar-item-padding-top-small": "0px",
    "--ctr-survey-question-panel-toolbar-item-padding-bottom-small": "0px",
    "--ctr-survey-question-panel-drag-area-drag-indicator-opacity": 0.5,
    "--ctr-preview-pager-padding-right": "0px",
    "--ctr-preview-pager-padding-left": "0px",
    "--ctr-label-padding-left": "0px",
    "--ctr-label-padding-right": "0px",
    "--ctr-survey-action-button-opacity-disabled": 0.25,
    "--ctr-survey-item-actionbar-drag-indicator-opacity": 0.5,
    "--ctr-property-grid-form-border-width-bottom": "0px",
    "--lbr-shadow-x-large-offset-x": "0px",
    "--lbr-shadow-x-large-offset-y": "32px",
    "--lbr-shadow-x-large-blur": "64px",
    "--lbr-shadow-x-large-spread": "0px",
    "--lbr-shadow-large-offset-x": "0px",
    "--lbr-shadow-large-offset-y": "16px",
    "--lbr-shadow-large-blur": "32px",
    "--lbr-shadow-large-spread": "0px",
    "--lbr-shadow-medium-offset-x": "0px",
    "--lbr-shadow-medium-offset-y": "2px",
    "--lbr-shadow-medium-blur": "8px",
    "--lbr-shadow-medium-spread": "0px",
    "--lbr-shadow-small-offset-x": "0px",
    "--lbr-shadow-small-offset-y": "1px",
    "--lbr-shadow-small-blur": "2px",
    "--lbr-shadow-small-spread": "0px",
    "--ctr-survey-question-panel-opacity-hidden": 0.25,
    "--ctr-button-group-item-text-opacity-disabled": 0.25,
    "--ctr-survey-header-logo-placeholder-icon-opacity": 0.5,
    "--ctr-toolbox-item-shadow-hovered-1-color": "#00000000",
    "--ctr-toolbox-item-shadow-pressed-color": "#00000000",
    "--ctr-toolbox-item-shadow-hovered-2-color": "#00000000",
    "--lbr-shadow-inner-offset-x": "0px",
    "--lbr-shadow-inner-offset-y": "1px",
    "--lbr-shadow-inner-blur": "2px",
    "--lbr-shadow-inner-spread": "0px",
    "--ctr-page-navigator-margin-top": "0px",
    "--ctr-toolbox-item-icon-opacity-disabled": 0.25,
    "--ctr-toolbox-item-shadow-subitem-1-color": "#00000000",
    "--ctr-toolbox-item-shadow-subitem-2-color": "#00000000",
    "--ctr-toolbox-submenu-margin-left": "0px",
    "--ctr-survey-page-drag-indicator-opacity": 0.5,
    "--ctr-toggle-button-thumb-border-width": "0px",
    "--ctr-line-height-unit": "8px",
    "--ctr-font-unit": "8px",
    "--ctr-spacing-unit": "8px",
    "--ctr-corner-radius-unit": "8px",
    "--ctr-stroke-unit": "1px",
    "--ctr-size-unit": "8px",
    "--lbr-stroke-unit": "1px",
    "--lbr-spacing-unit": "8px",
    "--lbr-font-unit": "8px",
    "--lbr-corner-radius-unit": "8px",
    "--lbr-size-unit": "8px",
    "--lbr-line-height-unit": "8px",
    "--ctr-preview-pager-item-icon-padding-left": "0px",
    "--ctr-preview-pager-item-icon-padding-right": "0px",
    "--ctr-preview-pager-item-icon-padding-top": "0px",
    "--ctr-preview-pager-item-icon-padding-bottom": "0px",
    "--ctr-toolbox-item-icon-color": "var(--sjs-primary-background-500)",
    "--ctr-toolbox-item-text-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-toolbox-item-icon-color-pressed": "var(--sjs-layer-1-foreground-100)",
    "--ctr-toolbox-item-text-color-pressed": "var(--sjs-layer-1-foreground-100)",
    "--ctr-toolbox-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-toolbox-border-color": "var(--sjs-border-25)",
    "--ctr-toolbox-border-width-right": "var(--sjs-stroke-x1)",
    "--ctr-toolbox-group-header-padding-left": "var(--sjs-spacing-x3)",
    "--ctr-toolbox-group-header-padding-right": "var(--sjs-spacing-x1)",
    "--ctr-menu-toolbar-button-corner-radius": "var(--sjs-corner-radius-x1)",
    "--ctr-menu-toolbar-button-background-color-selected": "var(--sjs-primary-background-10)",
    "--ctr-menu-toolbar-button-icon-color": "var(--sjs-layer-1-foreground-75)",
    "--ctr-menu-toolbar-button-icon-color-selected": "var(--sjs-primary-background-500)",
    "--ctr-toolbox-item-icon-color-hovered": "var(--sjs-primary-background-500)",
    "--ctr-toolbox-item-text-color-hovered": "var(--sjs-layer-1-foreground-100)",
    "--ctr-property-grid-form-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-list-item-corner-radius": "var(--sjs-corner-radius-x075)",
    "--ctr-button-group-item-border-color": "var(--sjs-border-10)",
    "--ctr-button-group-item-corner-radius": "var(--sjs-corner-radius-x05)",
    "--ctr-button-group-item-background-color": "var(--sjs-layer-3-background-500)",
    "--ctr-button-group-item-text-color": "var(--sjs-layer-3-foreground-100)",
    "--ctr-menu-item-icon-color": "var(--sjs-layer-1-foreground-75)",
    "--ctr-button-group-item-icon-color": "var(--sjs-layer-3-foreground-75)",
    "--ctr-button-group-item-background-color-hovered": "var(--sjs-layer-3-background-400)",
    "--ctr-button-group-item-text-color-selected": "var(--sjs-primary-background-500)",
    "--ctr-button-group-item-icon-color-selected": "var(--sjs-primary-background-500)",
    "--ctr-button-group-gap": "var(--sjs-spacing-x05)",
    "--ctr-editor-corner-radius": "var(--sjs-corner-radius-x075)",
    "--ctr-editor-background-color": "var(--sjs-layer-3-background-500)",
    "--ctr-editor-border-color": "var(--sjs-border-10)",
    "--ctr-editor-background-color-disabled": "var(--sjs-layer-1-background-500)",
    "--ctr-editor-button-icon-color": "var(--sjs-layer-3-foreground-75)",
    "--ctr-editor-button-corner-radius": "var(--sjs-corner-radius-x05)",
    "--ctr-button-group-item-background-color-selected": "var(--sjs-layer-1-background-500)",
    "--ctr-radio-button-background-color": "var(--sjs-layer-3-background-500)",
    "--ctr-radio-button-border-color": "var(--sjs-border-10)",
    "--ctr-checkbox-background-color": "var(--sjs-layer-3-background-500)",
    "--ctr-checkbox-border-color": "var(--sjs-border-10)",
    "--ctr-checkbox-border-width": "var(--sjs-stroke-x1)",
    "--ctr-checkbox-button-check-mark-color": "var(--sjs-primary-background-500)",
    "--ctr-radio-button-background-color-hovered": "var(--sjs-layer-3-background-400)",
    "--ctr-radio-button-background-color-disabled": "var(--sjs-layer-1-background-500)",
    "--ctr-checkbox-border-color-focused": "var(--sjs-primary-background-500)",
    "--ctr-checkbox-button-check-mark-color-disabled": "var(--sjs-border-25)",
    "--ctr-checkbox-background-color-hovered": "var(--sjs-layer-3-background-400)",
    "--ctr-checkbox-background-color-disabled": "var(--sjs-layer-1-background-500)",
    "--ctr-checkbox-corner-radius": "var(--sjs-corner-radius-x05)",
    "--ctr-list-padding-left": "var(--sjs-spacing-x1)",
    "--ctr-list-padding-right": "var(--sjs-spacing-x1)",
    "--ctr-actionbar-button-corner-radius": "var(--sjs-corner-radius-x1)",
    "--ctr-popup-menu-corner-radius": "var(--sjs-corner-radius-x1)",
    "--ctr-popup-menu-footer-padding-left": "var(--sjs-spacing-x1)",
    "--ctr-button-contextual-button-icon-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-button-group-item-background-color-disabled": "var(--sjs-layer-1-background-500)",
    "--ctr-radio-button-background-color-selected-hovered": "var(--sjs-layer-1-background-500)",
    "--ctr-checkbox-background-color-selected": "var(--sjs-layer-1-background-500)",
    "--ctr-checkbox-background-color-selected-hovered": "var(--sjs-layer-1-background-500)",
    "--ctr-radio-button-border-color-disabled": "var(--sjs-border-10)",
    "--ctr-checkbox-border-color-disabled": "var(--sjs-border-10)",
    "--ctr-button-group-corner-radius": "var(--sjs-corner-radius-x075)",
    "--ctr-button-group-padding": "var(--sjs-spacing-x05)",
    "--ctr-button-group-background-color": "var(--sjs-layer-3-background-500)",
    "--ctr-button-group-border-color": "var(--sjs-border-10)",
    "--ctr-button-group-border-width": "var(--sjs-stroke-x1)",
    "--ctr-button-group-item-border-width-selected": "var(--sjs-stroke-x1)",
    "--ctr-button-group-item-padding-horizontal": "var(--sjs-spacing-x2)",
    "--ctr-button-group-item-padding-vertical": "var(--sjs-spacing-x1)",
    "--ctr-button-group-background-color-disabled": "var(--sjs-layer-1-background-500)",
    "--ctr-button-group-border-color-focused": "var(--sjs-primary-background-500)",
    "--ctr-button-group-border-width-focused": "var(--sjs-stroke-x2)",
    "--ctr-button-group-item-text-color-disabled": "var(--sjs-layer-3-foreground-100)",
    "--ctr-survey-page-background-color-selected": "var(--sjs-special-background)",
    "--ctr-surface-background-color": "var(--sjs-special-background)",
    "--ctr-survey-question-panel-toolbar-item-corner-radius": "var(--lbr-corner-radius-x075)",
    "--ctr-popup-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-popup-corner-radius": "var(--sjs-corner-radius-x2)",
    "--ctr-shadow-large-color": "var(--sjs-special-glow)",
    "--ctr-shadow-medium-color": "var(--sjs-special-glow)",
    "--ctr-button-padding-horizontal": "var(--sjs-spacing-x6)",
    "--ctr-button-padding-vertical": "var(--sjs-spacing-x2)",
    "--ctr-button-contextual-button-margin-horizontal": "var(--sjs-spacing-x1)",
    "--ctr-button-contextual-button-margin-vertical": "var(--sjs-spacing-x1)",
    "--ctr-button-padding-horizontal-small": "var(--sjs-spacing-x4)",
    "--ctr-button-padding-vertical-small": "var(--sjs-spacing-x150)",
    "--ctr-button-corner-radius": "var(--sjs-corner-radius-x075)",
    "--ctr-button-corner-radius-small": "var(--sjs-corner-radius-x075)",
    "--ctr-list-item-icon-color": "var(--sjs-primary-background-500)",
    "--ctr-list-item-icon-color-selected": "var(--sjs-primary-foreground-100)",
    "--ctr-list-item-icon-color-disabled": "var(--sjs-layer-1-foreground-75)",
    "--ctr-editor-button-background-color-hovered": "var(--sjs-layer-3-background-400)",
    "--ctr-survey-page-toolbar-item-background-color-hovered": "var(--sjs-primary-background-10)",
    "--ctr-survey-page-toolbar-item-corner-radius": "var(--lbr-corner-radius-x05)",
    "--ctr-list-search-icon-color": "var(--sjs-layer-1-foreground-75)",
    "--ctr-caption-with-actions-text-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-label-text-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-label-text-color-disabled": "var(--sjs-layer-1-foreground-100)",
    "--ctr-caption-with-actions-text-color-disabled": "var(--sjs-layer-1-foreground-100)",
    "--ctr-radio-button-text-color-disabled": "var(--sjs-layer-1-foreground-100)",
    "--ctr-checkbox-text-color-disabled": "var(--sjs-layer-1-foreground-100)",
    "--ctr-button-group-item-background-color-selected-disabled": "var(--sjs-layer-1-background-400)",
    "--ctr-popup-haze-background-color": "var(--sjs-special-haze)",
    "--ctr-shadow-small-color": "var(--sjs-special-shadow)",
    "--ctr-shadow-x-large-color": "var(--sjs-special-glow)",
    "--ctr-actionbar-button-icon-color": "var(--sjs-layer-1-foreground-75)",
    "--ctr-actionbar-button-icon-color-hovered": "var(--sjs-layer-1-foreground-75)",
    "--ctr-actionbar-button-icon-color-with-text-disabled": "var(--sjs-layer-1-foreground-75)",
    "--ctr-list-item-submenu-arrow-color": "var(--sjs-layer-1-foreground-75)",
    "--ctr-page-navigator-button-icon-color": "var(--sjs-layer-3-foreground-75)",
    "--ctr-page-navigator-button-icon-color-hovered": "var(--sjs-layer-3-foreground-75)",
    "--ctr-property-grid-tabs-padding-left": "var(--sjs-spacing-x150)",
    "--ctr-property-grid-tabs-padding-bottom": "var(--sjs-spacing-x150)",
    "--ctr-property-grid-tabs-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-property-grid-tabs-border-width": "var(--sjs-stroke-x1)",
    "--ctr-property-grid-tabs-border-color": "var(--sjs-border-25)",
    "--ctr-property-grid-tabs-separator-margin": "var(--sjs-spacing-x150)",
    "--ctr-property-grid-tabs-gap": "var(--sjs-spacing-x150)",
    "--ctr-property-grid-border-color": "var(--sjs-border-25)",
    "--ctr-property-grid-placeholder-padding-left": "var(--sjs-spacing-x6)",
    "--ctr-property-grid-placeholder-padding-top": "var(--sjs-spacing-x12)",
    "--ctr-property-grid-placeholder-image-margin-top": "var(--sjs-spacing-x3)",
    "--ctr-property-grid-placeholder-text-gap": "var(--sjs-spacing-x1)",
    "--ctr-property-grid-placeholder-text-title-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-property-grid-placeholder-text-description-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-surface-placeholder-padding-left": "var(--sjs-spacing-x6)",
    "--ctr-surface-placeholder-padding-bottom": "var(--sjs-spacing-x16)",
    "--ctr-surface-placeholder-text-margin-top": "var(--sjs-spacing-x4)",
    "--ctr-surface-placeholder-text-margin-bottom": "var(--sjs-spacing-x6)",
    "--ctr-surface-placeholder-text-gap": "var(--sjs-spacing-x1)",
    "--ctr-surface-placeholder-max-width": "var(--sjs-size-x90)",
    "--ctr-surface-placeholder-padding-right": "var(--sjs-spacing-x6)",
    "--ctr-property-grid-tabs-padding-right": "var(--sjs-spacing-x150)",
    "--ctr-property-grid-tabs-padding-top": "var(--sjs-spacing-x150)",
    "--ctr-property-grid-placeholder-padding-right": "var(--sjs-spacing-x6)",
    "--ctr-popup-menu-footer-padding-right": "var(--sjs-spacing-x1)",
    "--ctr-editor-content-text-color": "var(--sjs-layer-3-foreground-100)",
    "--ctr-editor-label-color": "var(--sjs-layer-3-foreground-50)",
    "--ctr-editor-content-icon-color": "var(--sjs-layer-3-foreground-75)",
    "--ctr-editor-content-text-color-placeholder": "var(--sjs-layer-3-foreground-50)",
    "--ctr-editor-button-text-color": "var(--sjs-layer-3-foreground-100)",
    "--ctr-editor-label-color-disabled": "var(--sjs-layer-3-foreground-100)",
    "--ctr-editor-content-text-color-disabled": "var(--sjs-layer-1-foreground-100)",
    "--ctr-editor-button-icon-color-disabled": "var(--sjs-layer-3-foreground-75)",
    "--ctr-radio-button-text-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-checkbox-text-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-checkbox-description-text-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-actionbar-button-padding-left-large-icon": "var(--sjs-spacing-x1)",
    "--ctr-actionbar-button-padding-right-large-icon": "var(--sjs-spacing-x1)",
    "--ctr-actionbar-button-padding-top-large-icon": "var(--sjs-spacing-x1)",
    "--ctr-actionbar-button-padding-bottom-large-icon": "var(--sjs-spacing-x1)",
    "--ctr-actionbar-button-text-color": "var(--sjs-primary-background-500)",
    "--ctr-actionbar-button-background-color-hovered": "var(--sjs-layer-1-background-400)",
    "--ctr-actionbar-button-padding-left-small-icon": "var(--sjs-spacing-x05)",
    "--ctr-actionbar-button-padding-right-small-icon": "var(--sjs-spacing-x05)",
    "--ctr-actionbar-button-padding-top-small-icon": "var(--sjs-spacing-x05)",
    "--ctr-actionbar-button-padding-bottom-small-icon": "var(--sjs-spacing-x05)",
    "--ctr-actionbar-button-padding-left-medium-text": "var(--sjs-spacing-x2)",
    "--ctr-actionbar-button-padding-right-medium-text": "var(--sjs-spacing-x2)",
    "--ctr-actionbar-button-padding-top-medium-text": "var(--sjs-spacing-x05)",
    "--ctr-actionbar-button-padding-bottom-medium-text": "var(--sjs-spacing-x05)",
    "--ctr-actionbar-button-text-color-disabled": "var(--sjs-layer-1-foreground-100)",
    "--ctr-list-item-gap": "var(--sjs-spacing-x2)",
    "--ctr-list-item-padding-left": "var(--sjs-spacing-x2)",
    "--ctr-list-item-padding-right": "var(--sjs-spacing-x6)",
    "--ctr-list-item-padding-top": "var(--sjs-spacing-x150)",
    "--ctr-list-item-padding-bottom": "var(--sjs-spacing-x150)",
    "--ctr-list-item-padding-bottom-with-icon": "var(--sjs-spacing-x150)",
    "--ctr-list-item-padding-top-with-icon": "var(--sjs-spacing-x150)",
    "--ctr-list-item-background-color-hovered": "var(--sjs-primary-background-10)",
    "--ctr-list-item-background-color-selected": "var(--sjs-primary-background-500)",
    "--ctr-list-item-text-color-selected": "var(--sjs-primary-foreground-100)",
    "--ctr-list-item-text-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-list-item-text-color-hovered": "var(--sjs-layer-1-foreground-100)",
    "--ctr-list-item-submenu-arrow-color-hovered-item": "var(--sjs-layer-1-foreground-75)",
    "--ctr-list-item-icon-color-hovered": "var(--sjs-primary-background-500)",
    "--ctr-list-item-contextual-buttons-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-list-item-color-swatch-corner-radius": "var(--sjs-corner-radius-x05)",
    "--ctr-list-item-color-swatch-width": "var(--sjs-font-size-x4)",
    "--ctr-list-item-color-swatch-height": "var(--sjs-font-size-x3)",
    "--ctr-list-item-color-swatch-border-color": "var(--sjs-border-25-overlay)",
    "--ctr-editor-color-swatch-corner-radius": "var(--sjs-corner-radius-x05)",
    "--ctr-editor-button-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-editor-button-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-editor-button-padding-left": "var(--sjs-spacing-x1)",
    "--ctr-editor-button-padding-right": "var(--sjs-spacing-x1)",
    "--ctr-actionbar-button-icon-width-small": "var(--sjs-font-size-x2)",
    "--ctr-actionbar-button-icon-height-small": "var(--sjs-font-size-x2)",
    "--ctr-actionbar-button-icon-width": "var(--sjs-font-size-x3)",
    "--ctr-actionbar-button-icon-height": "var(--sjs-font-size-x3)",
    "--ctr-editor-color-swatch-icon-width": "var(--sjs-font-size-x2)",
    "--ctr-editor-color-swatch-icon-height": "var(--sjs-font-size-x2)",
    "--ctr-editor-button-icon-width": "var(--sjs-font-size-x3)",
    "--ctr-editor-button-icon-height": "var(--sjs-font-size-x3)",
    "--ctr-checkbox-button-width": "var(--sjs-font-size-x3)",
    "--ctr-checkbox-button-height": "var(--sjs-font-size-x3)",
    "--ctr-menu-item-icon-width": "var(--sjs-font-size-x3)",
    "--ctr-menu-item-icon-height": "var(--sjs-font-size-x3)",
    "--ctr-menu-item-text-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-menu-item-padding-left": "var(--sjs-spacing-x3)",
    "--ctr-menu-item-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-menu-item-border-color-selected": "var(--sjs-primary-background-500)",
    "--ctr-menu-item-background-color-hovered": "var(--sjs-layer-1-background-400)",
    "--ctr-menu-toolbar-button-text-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-menu-toolbar-button-icon-width": "var(--sjs-font-size-x3)",
    "--ctr-menu-toolbar-button-icon-height": "var(--sjs-font-size-x3)",
    "--ctr-menu-toolbar-button-padding-left": "var(--sjs-spacing-x1)",
    "--ctr-menu-toolbar-button-gap": "var(--sjs-spacing-x1)",
    "--ctr-menu-toolbar-button-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-menu-toolbar-button-text-color-secondary": "var(--sjs-layer-1-foreground-50)",
    "--ctr-menu-toolbar-button-text-color-selected": "var(--sjs-primary-background-500)",
    "--ctr-menu-toolbar-button-background-color-hovered": "var(--sjs-layer-1-background-400)",
    "--ctr-menu-toolbar-button-background-color-pressed": "var(--sjs-layer-1-background-400)",
    "--ctr-toolbox-item-icon-width": "var(--sjs-font-size-x3)",
    "--ctr-toolbox-item-icon-height": "var(--sjs-font-size-x3)",
    "--ctr-toolbox-item-background-color-hovered": "var(--sjs-primary-background-10)",
    "--ctr-toolbox-item-padding-left": "var(--sjs-spacing-x150)",
    "--ctr-toolbox-item-padding-right": "var(--sjs-spacing-x2)",
    "--ctr-toolbox-item-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-toolbox-item-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-toolbox-item-corner-radius": "var(--sjs-corner-radius-x1)",
    "--ctr-toolbox-item-padding-right-no-text": "var(--sjs-spacing-x1)",
    "--ctr-toolbox-item-border-color-floating": "var(--sjs-secondary-background-500)",
    "--ctr-toolbox-button-background-color-hovered": "var(--sjs-primary-background-10)",
    "--ctr-page-navigator-item-dot-radius-small": "var(--sjs-font-size-x075)",
    "--ctr-page-navigator-item-dot-radius-large": "var(--sjs-font-size-x1)",
    "--ctr-page-navigator-item-dot-border-color-selected": "var(--sjs-layer-3-foreground-75)",
    "--ctr-page-navigator-item-dot-color-hovered": "var(--sjs-primary-background-500)",
    "--ctr-page-navigator-item-dot-color-default": "var(--sjs-layer-3-foreground-75)",
    "--ctr-page-navigator-item-dot-color-selected": "var(--sjs-special-background)",
    "--ctr-page-navigator-item-dot-container-width": "var(--sjs-font-size-x250)",
    "--ctr-page-navigator-item-dot-container-height": "var(--sjs-font-size-x250)",
    "--ctr-page-navigator-item-background-color-hovered": "var(--sjs-layer-1-background-500)",
    "--ctr-page-navigator-item-text-color-hovered": "var(--sjs-layer-1-foreground-100)",
    "--ctr-page-navigator-item-padding-left-hovered": "var(--sjs-spacing-x2)",
    "--ctr-page-navigator-item-padding-right": "var(--sjs-spacing-x125)",
    "--ctr-page-navigator-item-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-page-navigator-item-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-page-navigator-item-gap": "var(--sjs-spacing-x1)",
    "--ctr-page-navigator-item-corner-radius": "var(--sjs-corner-radius-x075)",
    "--ctr-page-navigator-item-padding-left": "var(--sjs-spacing-x125)",
    "--ctr-page-navigator-button-padding": "var(--sjs-spacing-x1)",
    "--ctr-page-navigator-button-icon-width": "var(--sjs-font-size-x3)",
    "--ctr-page-navigator-button-icon-height": "var(--sjs-font-size-x3)",
    "--ctr-page-navigator-button-corner-radius": "var(--sjs-corner-radius-x1)",
    "--ctr-page-navigator-button-background-color-hovered": "var(--sjs-primary-background-10)",
    "--ctr-page-navigator-button-background-color-pressed": "var(--sjs-primary-background-10)",
    "--ctr-page-navigator-button-icon-color-pressed": "var(--sjs-layer-3-foreground-75)",
    "--ctr-popup-menu-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-popup-menu-pointer-color": "var(--sjs-layer-1-background-500)",
    "--ctr-button-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-button-text-color": "var(--sjs-primary-background-500)",
    "--ctr-button-gap": "var(--sjs-spacing-x4)",
    "--ctr-button-background-color-hovered": "var(--sjs-primary-background-10)",
    "--ctr-button-text-color-disabled": "var(--sjs-layer-1-foreground-100)",
    "--ctr-button-background-color-danger": "var(--sjs-semantic-red-background-500)",
    "--ctr-button-border-color-focused": "var(--sjs-primary-background-500)",
    "--ctr-button-border-width-focused": "var(--sjs-stroke-x2)",
    "--ctr-button-background-color-cta": "var(--sjs-primary-background-500)",
    "--ctr-button-background-color-cta-hovered": "var(--sjs-primary-background-400)",
    "--ctr-button-text-color-cta": "var(--sjs-primary-foreground-100)",
    "--ctr-collapse-button-icon-width": "var(--sjs-font-size-x3)",
    "--ctr-collapse-button-icon-height": "var(--sjs-font-size-x3)",
    "--ctr-collapse-button-padding": "var(--sjs-spacing-x150)",
    "--ctr-collapse-button-corner-radius": "var(--sjs-corner-radius-x1)",
    "--ctr-collapse-button-icon-color": "var(--sjs-layer-3-foreground-75)",
    "--ctr-collapse-button-icon-color-hovered": "var(--sjs-layer-3-foreground-75)",
    "--ctr-collapse-button-background-color-hovered": "var(--sjs-primary-background-10)",
    "--ctr-property-grid-switcher-text-color": "var(--sjs-layer-1-foreground-75)",
    "--ctr-property-grid-chapter-caption-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-property-grid-chapter-caption-border-color": "var(--sjs-border-25)",
    "--ctr-property-grid-chapter-caption-text-color": "var(--sjs-layer-1-foreground-75)",
    "--ctr-property-grid-chapter-caption-text-color-selected": "var(--sjs-layer-1-foreground-100)",
    "--ctr-property-grid-chapter-caption-background-color-hovered": "var(--sjs-layer-1-background-400)",
    "--ctr-property-grid-chapter-caption-background-color-focused": "var(--sjs-layer-1-background-400)",
    "--ctr-property-grid-chapter-caption-text-color-disabled": "var(--sjs-layer-1-foreground-100)",
    "--ctr-expression-item-padding-left": "var(--sjs-spacing-x2)",
    "--ctr-expression-item-background-color-parameter": "var(--sjs-semantic-blue-background-10)",
    "--ctr-expression-item-padding-right": "var(--sjs-spacing-x2)",
    "--ctr-expression-item-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-expression-item-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-expression-item-corner-radius": "var(--sjs-corner-radius-x1)",
    "--ctr-expression-item-text-color-parameter": "var(--sjs-layer-3-foreground-100)",
    "--ctr-expression-item-background-color-parameter-hovered": "var(--sjs-semantic-blue-background-500)",
    "--ctr-expression-item-background-color-parameter-error": "var(--sjs-layer-1-background-500)",
    "--ctr-expression-item-text-color-parameter-hovered": "var(--sjs-semantic-blue-foreground-100)",
    "--ctr-expression-item-text-color-parameter-empty": "var(--sjs-layer-3-foreground-50)",
    "--ctr-expression-item-text-color-parameter-error": "var(--sjs-semantic-red-background-500)",
    "--ctr-expression-item-text-color-operator": "var(--sjs-layer-3-foreground-100)",
    "--ctr-expression-item-text-color-operator-hovered": "var(--sjs-semantic-yellow-foreground-100)",
    "--ctr-expression-item-text-color-operator-empty": "var(--sjs-layer-3-foreground-50)",
    "--ctr-expression-item-text-color-conjunction": "var(--sjs-layer-3-foreground-100)",
    "--ctr-expression-item-text-color-conjunction-hovered": "var(--sjs-semantic-yellow-foreground-100)",
    "--ctr-expression-item-text-color-conjunction-empty": "var(--sjs-layer-3-foreground-50)",
    "--ctr-expression-item-text-color-action": "var(--sjs-layer-3-foreground-100)",
    "--ctr-expression-item-text-color-action-hovered": "var(--sjs-semantic-red-foreground-100)",
    "--ctr-expression-item-text-color-action-empty": "var(--sjs-layer-3-foreground-50)",
    "--ctr-expression-item-text-color-button": "var(--sjs-primary-background-500)",
    "--ctr-expression-item-text-color-button-hovered": "var(--sjs-primary-foreground-100)",
    "--ctr-expression-item-background-color-operator": "var(--sjs-semantic-yellow-background-10)",
    "--ctr-expression-item-background-color-operator-hovered": "var(--sjs-semantic-yellow-background-500)",
    "--ctr-expression-item-background-color-conjunction": "var(--sjs-semantic-yellow-background-10)",
    "--ctr-expression-item-background-color-conjunction-hovered": "var(--sjs-semantic-yellow-background-500)",
    "--ctr-expression-item-background-color-action": "var(--sjs-semantic-red-background-10)",
    "--ctr-expression-item-background-color-action-hovered": "var(--sjs-semantic-red-background-500)",
    "--ctr-expression-item-background-color-button": "var(--sjs-primary-background-10)",
    "--ctr-expression-item-background-color-button-hovered": "var(--sjs-primary-background-500)",
    "--ctr-expression-item-text-color-function": "var(--sjs-layer-3-foreground-100)",
    "--ctr-data-table-background-color": "var(--sjs-layer-1-background-400)",
    "--ctr-data-table-border-color": "var(--sjs-border-25)",
    "--ctr-data-table-border-width": "var(--sjs-stroke-x1)",
    "--ctr-separator-width": "var(--sjs-size-x0125)",
    "--ctr-separator-color": "var(--sjs-border-25)",
    "--ctr-separator-margin-vertical-small": "var(--sjs-spacing-x1)",
    "--ctr-popup-padding-left": "var(--sjs-spacing-x4)",
    "--ctr-popup-padding-right": "var(--sjs-spacing-x4)",
    "--ctr-popup-padding-top": "var(--sjs-spacing-x4)",
    "--ctr-popup-padding-bottom": "var(--sjs-spacing-x4)",
    "--ctr-popup-gap": "var(--sjs-spacing-x4)",
    "--ctr-popup-title-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-popup-message-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-popup-buttons-gap": "var(--sjs-spacing-x2)",
    "--ctr-popup-description-color": "var(--sjs-layer-1-foreground-75)",
    "--ctr-toolbox-group-header-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-toolbox-group-header-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-toolbox-group-header-border-color": "var(--sjs-border-25)",
    "--ctr-toolbox-group-header-border-width-bottom": "var(--sjs-stroke-x1)",
    "--ctr-toolbox-group-header-text-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-notification-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-notification-text-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-notification-text-color-error": "var(--sjs-semantic-red-foreground-100)",
    "--ctr-notification-background-color-error": "var(--sjs-semantic-red-background-500)",
    "--ctr-string-table-row-padding-left": "var(--sjs-spacing-x3)",
    "--ctr-string-table-row-padding-right": "var(--sjs-spacing-x3)",
    "--ctr-string-table-row-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-string-table-row-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-string-table-row-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-string-table-row-border-color": "var(--sjs-border-10)",
    "--ctr-string-table-row-text-color-title": "var(--sjs-layer-1-foreground-100)",
    "--ctr-string-table-row-expand-button-icon-color-default": "var(--sjs-layer-1-foreground-75)",
    "--ctr-string-table-header-background-color": "var(--sjs-layer-3-background-500)",
    "--ctr-string-table-header-text-color": "var(--sjs-layer-3-foreground-75)",
    "--ctr-string-table-group-header-background-color": "var(--sjs-layer-2-background-500)",
    "--ctr-string-table-group-header-text-color": "var(--sjs-layer-2-foreground-75)",
    "--ctr-text-decor-text-color-code": "var(--sjs-layer-1-foreground-100)",
    "--ctr-menu-border-color": "var(--sjs-border-25)",
    "--ctr-menu-toolbar-gap": "var(--sjs-spacing-x2)",
    "--ctr-menu-toolbar-padding-horizontal": "var(--sjs-spacing-x2)",
    "--ctr-menu-toolbar-padding-vertical": "var(--sjs-spacing-x150)",
    "--ctr-popup-menu-footer-padding-top": "var(--sjs-spacing-x05)",
    "--ctr-popup-menu-footer-padding-bottom": "var(--sjs-spacing-x05)",
    "--ctr-popup-menu-footer-background-color": "var(--sjs-layer-3-background-500)",
    "--ctr-popup-menu-footer-border-color": "var(--sjs-border-25)",
    "--ctr-property-grid-header-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-property-grid-header-padding-left": "var(--sjs-spacing-x2)",
    "--ctr-property-grid-header-padding-right": "var(--sjs-spacing-x2)",
    "--ctr-property-grid-header-padding-top": "var(--sjs-spacing-x150)",
    "--ctr-property-grid-header-padding-bottom": "var(--sjs-spacing-x150)",
    "--ctr-property-grid-header-border-color": "var(--sjs-primary-background-500)",
    "--ctr-property-grid-header-border-width-bottom": "var(--sjs-stroke-x2)",
    "--ctr-caption-with-actions-description-text-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-caption-with-actions-description-text-color-disabled": "var(--sjs-layer-1-foreground-100)",
    "--ctr-data-table-cell-padding-left": "var(--sjs-spacing-x1)",
    "--ctr-data-table-cell-padding-right": "var(--sjs-spacing-x1)",
    "--ctr-data-table-cell-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-data-table-cell-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-data-table-cell-drop-down-arrow-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-data-table-cell-drop-down-arrow-width": "var(--sjs-font-size-x3)",
    "--ctr-data-table-cell-drop-down-arrow-height": "var(--sjs-font-size-x3)",
    "--ctr-data-table-cell-text-color-header": "var(--sjs-layer-3-foreground-50)",
    "--ctr-data-table-cell-border-color-focused": "var(--sjs-primary-background-500)",
    "--ctr-data-table-cell-corner-radius-focused": "var(--sjs-corner-radius-x075)",
    "--ctr-data-table-row-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-data-table-row-background-color-header": "var(--sjs-layer-3-background-500)",
    "--ctr-data-table-row-border-color": "var(--sjs-border-10)",
    "--ctr-data-table-row-border-width": "var(--sjs-stroke-x1)",
    "--ctr-data-table-row-padding-top": "var(--sjs-spacing-x05)",
    "--ctr-data-table-row-padding-bottom": "var(--sjs-spacing-x05)",
    "--ctr-data-table-row-padding-right": "var(--sjs-spacing-x05)",
    "--ctr-data-table-row-drag-area-icon-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-data-table-row-drag-area-icon-width": "var(--sjs-font-size-x3)",
    "--ctr-data-table-row-drag-area-icon-height": "var(--sjs-font-size-x3)",
    "--ctr-data-table-row-drag-area-padding-left": "var(--sjs-spacing-x1)",
    "--ctr-data-table-row-drag-area-padding-right": "var(--sjs-spacing-x1)",
    "--ctr-data-table-row-drag-area-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-data-table-row-drag-area-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-data-table-row-padding-top-header": "var(--sjs-spacing-x1)",
    "--ctr-data-table-row-padding-bottom-header": "var(--sjs-spacing-x1)",
    "--ctr-data-table-row-background-color-drop-spot": "var(--sjs-layer-1-background-400)",
    "--ctr-error-message-background-color": "var(--sjs-semantic-red-background-10)",
    "--ctr-error-message-corner-radius": "var(--sjs-corner-radius-x05)",
    "--ctr-error-message-gap": "var(--sjs-spacing-x1)",
    "--ctr-error-message-padding-left": "var(--sjs-spacing-x150)",
    "--ctr-error-message-padding-right": "var(--sjs-spacing-x150)",
    "--ctr-error-message-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-error-message-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-error-message-icon-width": "var(--sjs-font-size-x3)",
    "--ctr-error-message-icon-height": "var(--sjs-font-size-x3)",
    "--ctr-error-message-icon-color": "var(--sjs-semantic-red-background-500)",
    "--ctr-error-message-text-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-toolbox-separator-color": "var(--sjs-border-25)",
    "--ctr-toolbox-separator-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-toolbox-separator-height": "var(--sjs-size-x0125)",
    "--ctr-toolbox-separator-max-width": "var(--sjs-size-max)",
    "--ctr-toolbox-separator-padding-left": "var(--sjs-spacing-x2)",
    "--ctr-toolbox-separator-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-toolbox-gap": "var(--sjs-spacing-x05)",
    "--ctr-toolbox-padding-top": "var(--sjs-spacing-x150)",
    "--ctr-toolbox-padding-bottom": "var(--sjs-spacing-x2)",
    "--ctr-toolbox-group-gap": "var(--sjs-spacing-x05)",
    "--ctr-toolbox-group-padding-left": "var(--sjs-spacing-x150)",
    "--ctr-toolbox-group-padding-right": "var(--sjs-spacing-x150)",
    "--ctr-button-contextual-button-corner-radius": "var(--sjs-corner-radius-x05)",
    "--ctr-button-contextual-button-padding-left": "var(--sjs-spacing-x1)",
    "--ctr-button-contextual-button-padding-right": "var(--sjs-spacing-x1)",
    "--ctr-button-contextual-button-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-button-contextual-button-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-button-contextual-button-background-color-hovered": "var(--sjs-primary-background-10)",
    "--ctr-button-contextual-button-icon-width": "var(--sjs-font-size-x3)",
    "--ctr-button-contextual-button-icon-height": "var(--sjs-font-size-x3)",
    "--ctr-button-contextual-button-background-color-focused": "var(--sjs-primary-background-10)",
    "--ctr-survey-question-panel-toolbar-item-gap": "var(--lbr-spacing-x05)",
    "--ctr-survey-question-panel-toolbar-item-padding-left": "var(--lbr-spacing-x05)",
    "--ctr-survey-question-panel-toolbar-item-padding-right": "var(--lbr-spacing-x05)",
    "--ctr-survey-question-panel-toolbar-item-padding-top": "var(--lbr-spacing-x05)",
    "--ctr-survey-question-panel-toolbar-item-padding-bottom": "var(--lbr-spacing-x05)",
    "--ctr-survey-question-panel-toolbar-item-background-color-hovered": "var(--sjs-secondary-background-10)",
    "--ctr-survey-question-panel-toolbar-item-icon-container-width": "var(--lbr-size-x3)",
    "--ctr-survey-question-panel-toolbar-item-icon-container-height": "var(--lbr-size-x3)",
    "--ctr-survey-question-panel-toolbar-item-icon-padding-left": "var(--lbr-spacing-x05)",
    "--ctr-survey-question-panel-toolbar-item-icon-padding-right": "var(--lbr-spacing-x05)",
    "--ctr-survey-question-panel-toolbar-item-icon-padding-top": "var(--lbr-spacing-x05)",
    "--ctr-survey-question-panel-toolbar-item-icon-padding-bottom": "var(--lbr-spacing-x05)",
    "--ctr-survey-question-panel-toolbar-item-icon-color": "var(--sjs-secondary-background-500)",
    "--ctr-survey-question-panel-toolbar-item-text-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-survey-question-panel-toolbar-item-background-color-selected": "var(--sjs-secondary-background-10)",
    "--ctr-survey-question-panel-toolbar-item-icon-color-pressed": "var(--sjs-layer-1-foreground-75)",
    "--ctr-survey-page-toolbar-item-icon-color-pressed": "var(--sjs-layer-3-foreground-75)",
    "--ctr-survey-page-toolbar-item-text-color": "var(--sjs-layer-3-foreground-100)",
    "--ctr-survey-question-panel-toolbar-item-padding-right-with-text": "var(--lbr-spacing-x1)",
    "--ctr-survey-page-toolbar-item-background-color-selected": "var(--sjs-primary-background-10)",
    "--ctr-survey-question-panel-drag-area-drag-indicator-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-survey-question-panel-drag-area-drag-indicator-width": "var(--lbr-size-x3)",
    "--ctr-survey-question-panel-drag-area-drag-indicator-height": "var(--lbr-size-x2)",
    "--ctr-preview-pager-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-preview-pager-border-color": "var(--sjs-border-25)",
    "--ctr-preview-pager-border-width-top": "var(--sjs-stroke-x1)",
    "--ctr-preview-pager-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-preview-pager-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-survey-question-panel-toolbar-item-icon-color-disabled": "var(--sjs-layer-1-foreground-50)",
    "--ctr-code-viewer-code-error-row-icon-width": "var(--sjs-font-size-x2)",
    "--ctr-code-viewer-code-error-row-icon-height": "var(--sjs-font-size-x2)",
    "--ctr-code-viewer-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-code-viewer-text-color": "var(--sjs-code-gray-300)",
    "--ctr-code-viewer-line-number-panel-background-color": "var(--sjs-layer-2-background-500)",
    "--ctr-code-viewer-line-number-panel-text-color": "var(--sjs-layer-2-foreground-50)",
    "--ctr-property-grid-group-caption-text-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-toolbox-group-header-expand-button-color": "var(--sjs-layer-1-foreground-75)",
    "--lbr-question-panel-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-label-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-label-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-editor-padding-left": "var(--sjs-spacing-x05)",
    "--ctr-editor-padding-right": "var(--sjs-spacing-x05)",
    "--ctr-editor-padding-top": "var(--sjs-spacing-x05)",
    "--ctr-editor-padding-bottom": "var(--sjs-spacing-x05)",
    "--ctr-editor-gap": "var(--sjs-spacing-x05)",
    "--ctr-editor-border-width": "var(--sjs-stroke-x1)",
    "--ctr-editor-content-margin-left": "var(--sjs-spacing-x150)",
    "--ctr-editor-content-margin-right": "var(--sjs-spacing-x150)",
    "--ctr-editor-content-margin-top": "var(--sjs-spacing-x1)",
    "--ctr-editor-content-margin-bottom": "var(--sjs-spacing-x1)",
    "--ctr-editor-color-swatch-margin-left": "var(--sjs-spacing-x150)",
    "--ctr-editor-label-padding-left": "var(--sjs-spacing-x150)",
    "--ctr-editor-label-padding-right": "var(--sjs-spacing-x2)",
    "--ctr-editor-label-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-editor-label-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-editor-buttons-gap": "var(--sjs-spacing-x05)",
    "--ctr-editor-border-width-focused": "var(--sjs-stroke-x2)",
    "--ctr-editor-border-color-focused": "var(--sjs-primary-background-500)",
    "--ctr-editor-border-color-error": "var(--sjs-semantic-red-background-500)",
    "--ctr-checkbox-list-gap-vertical": "var(--sjs-spacing-x2)",
    "--ctr-surface-placeholder-text-title-color": "var(--sjs-layer-3-foreground-100)",
    "--ctr-surface-placeholder-text-description-color": "var(--sjs-layer-3-foreground-50)",
    "--ctr-survey-page-placeholder-text-color": "var(--sjs-layer-3-foreground-50)",
    "--ctr-survey-placeholder-gap": "var(--lbr-spacing-x05)",
    "--ctr-survey-placeholder-padding-left": "var(--lbr-spacing-x8)",
    "--ctr-survey-placeholder-padding-right": "var(--lbr-spacing-x8)",
    "--ctr-survey-placeholder-padding-top-with-button": "var(--lbr-spacing-x8)",
    "--ctr-survey-placeholder-padding-bottom-with-button": "var(--lbr-spacing-x6)",
    "--ctr-survey-placeholder-text-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-survey-placeholder-border-color": "var(--sjs-border-25)",
    "--ctr-survey-drop-indicator-color": "var(--sjs-primary-background-500)",
    "--ctr-property-grid-search-background-color": "var(--sjs-layer-3-background-500)",
    "--ctr-property-grid-search-padding-left": "var(--sjs-spacing-x2)",
    "--ctr-property-grid-search-padding-right": "var(--sjs-spacing-x2)",
    "--ctr-property-grid-search-padding-top": "var(--sjs-spacing-x2)",
    "--ctr-property-grid-search-padding-bottom": "var(--sjs-spacing-x2)",
    "--ctr-property-grid-search-text-color": "var(--sjs-layer-3-foreground-100)",
    "--ctr-property-grid-search-text-color-placeholder": "var(--sjs-layer-3-foreground-50)",
    "--ctr-search-toolbar-gap": "var(--sjs-spacing-x05)",
    "--ctr-property-grid-search-border-color": "var(--sjs-border-10)",
    "--ctr-property-grid-search-gap": "var(--sjs-spacing-x1)",
    "--ctr-search-button-corner-radius": "var(--sjs-corner-radius-x05)",
    "--ctr-search-button-padding-vertical": "var(--sjs-spacing-x05)",
    "--ctr-search-button-padding-horizontal": "var(--sjs-spacing-x05)",
    "--ctr-search-button-background-color-hovered": "var(--sjs-layer-1-background-400)",
    "--ctr-search-button-icon-width": "var(--sjs-font-size-x2)",
    "--ctr-search-button-icon-height": "var(--sjs-font-size-x2)",
    "--ctr-property-grid-search-button-icon-color": "var(--sjs-layer-3-foreground-50)",
    "--ctr-property-grid-search-button-background-color-hovered": "var(--sjs-layer-3-background-400)",
    "--ctr-property-grid-search-icon-color": "var(--sjs-layer-3-foreground-50)",
    "--ctr-property-grid-search-count-text-color": "var(--sjs-layer-3-foreground-50)",
    "--ctr-scrollbar-background-color": "var(--sjs-border-25-overlay)",
    "--ctr-scrollbar-padding-vertical": "var(--sjs-spacing-x05)",
    "--ctr-scrollbar-width": "var(--sjs-size-x150)",
    "--ctr-survey-question-panel-toolbar-gap": "var(--lbr-spacing-x1)",
    "--ctr-survey-info-panel-background-color": "var(--sjs-layer-2-background-500)",
    "--ctr-survey-info-panel-text-color": "var(--sjs-layer-2-foreground-50)",
    "--ctr-survey-action-button-padding-left": "var(--lbr-spacing-x3)",
    "--ctr-survey-action-button-padding-right": "var(--lbr-spacing-x3)",
    "--ctr-survey-action-button-padding-top": "var(--lbr-spacing-x1)",
    "--ctr-survey-action-button-padding-bottom": "var(--lbr-spacing-x1)",
    "--ctr-survey-action-button-corner-radius": "var(--lbr-corner-radius-x1)",
    "--ctr-survey-action-button-icon-width": "var(--lbr-size-x3)",
    "--ctr-survey-action-button-icon-height": "var(--lbr-size-x3)",
    "--ctr-survey-action-button-icon-width-small": "var(--lbr-size-x2)",
    "--ctr-survey-action-button-icon-height-small": "var(--lbr-size-x2)",
    "--ctr-survey-action-button-icon-color-positive": "var(--sjs-primary-background-500)",
    "--ctr-survey-action-button-icon-color-negative": "var(--sjs-semantic-red-background-500)",
    "--ctr-survey-action-button-text-color-positive": "var(--sjs-primary-background-500)",
    "--ctr-survey-action-button-text-color-disabled": "var(--sjs-layer-1-foreground-100)",
    "--ctr-survey-action-button-background-color-hovered-positive": "var(--sjs-primary-background-10)",
    "--ctr-survey-action-button-background-color-hovered-negative": "var(--sjs-semantic-red-background-10)",
    "--ctr-survey-action-button-icon-color-disabled": "var(--sjs-layer-1-foreground-50)",
    "--ctr-survey-contextual-button-corner-radius": "var(--lbr-corner-radius-round)",
    "--ctr-survey-contextual-button-width": "var(--lbr-size-x6)",
    "--ctr-survey-contextual-button-height": "var(--lbr-size-x6)",
    "--ctr-survey-contextual-button-padding-left": "var(--lbr-spacing-x150)",
    "--ctr-survey-contextual-button-padding-right": "var(--lbr-spacing-x150)",
    "--ctr-survey-contextual-button-padding-top": "var(--lbr-spacing-x150)",
    "--ctr-survey-contextual-button-padding-bottom": "var(--lbr-spacing-x150)",
    "--ctr-survey-contextual-button-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-property-grid-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-survey-header-logo-placeholder-icon-color": "var(--sjs-layer-3-foreground-50)",
    "--ctr-survey-header-logo-placeholder-icon-width": "var(--lbr-size-x6)",
    "--ctr-survey-header-logo-placeholder-icon-height": "var(--lbr-size-x6)",
    "--ctr-survey-image-picker-drop-spot-color": "var(--sjs-layer-2-background-500)",
    "--ctr-data-table-row-corner-radius-floating": "var(--sjs-corner-radius-x05)",
    "--ctr-survey-item-actionbar-drag-indicator-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-survey-item-actionbar-drag-indicator-padding-left": "var(--lbr-spacing-x1)",
    "--ctr-survey-item-actionbar-drag-indicator-padding-right": "var(--lbr-spacing-x1)",
    "--ctr-survey-item-actionbar-drag-indicator-padding-top": "var(--lbr-spacing-x05)",
    "--ctr-survey-item-actionbar-drag-indicator-padding-bottom": "var(--lbr-spacing-x05)",
    "--ctr-survey-item-actionbar-drag-indicator-width": "var(--lbr-size-x3)",
    "--ctr-survey-item-actionbar-drag-indicator-height": "var(--lbr-size-x3)",
    "--ctr-property-grid-form-padding-left": "var(--sjs-spacing-x4)",
    "--ctr-property-grid-form-padding-right": "var(--sjs-spacing-x4)",
    "--ctr-property-grid-form-padding-top": "var(--sjs-spacing-x2)",
    "--ctr-property-grid-form-padding-bottom": "var(--sjs-spacing-x5)",
    "--ctr-property-grid-form-gap": "var(--sjs-spacing-x2)",
    "--ctr-page-navigator-padding-left": "var(--sjs-spacing-x2)",
    "--ctr-page-navigator-padding-right": "var(--sjs-spacing-x2)",
    "--ctr-page-navigator-padding-top": "var(--sjs-spacing-x2)",
    "--ctr-page-navigator-padding-bottom": "var(--sjs-spacing-x2)",
    "--ctr-page-navigator-gap": "var(--sjs-spacing-x2)",
    "--ctr-toolbox-search-padding-left": "var(--sjs-spacing-x3)",
    "--ctr-toolbox-search-padding-right": "var(--sjs-spacing-x2)",
    "--ctr-toolbox-search-padding-top": "var(--sjs-spacing-x250)",
    "--ctr-toolbox-search-icon-color-placeholder": "var(--sjs-layer-1-foreground-50)",
    "--ctr-toolbox-search-padding-bottom": "var(--sjs-spacing-x250)",
    "--ctr-toolbox-search-gap": "var(--sjs-spacing-x1)",
    "--ctr-toolbox-search-text-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-toolbox-search-border-width-bottom": "var(--sjs-stroke-x1)",
    "--ctr-toolbox-search-text-color-placeholder": "var(--sjs-layer-1-foreground-50)",
    "--ctr-toolbox-search-clear-button-icon-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-toolbox-search-clear-button-background-color-hovered": "var(--sjs-semantic-red-background-10)",
    "--ctr-search-icon-color": "var(--sjs-layer-1-foreground-75)",
    "--ctr-toolbox-search-icon-color": "var(--sjs-primary-background-500)",
    "--ctr-toolbox-search-clear-button-icon-color-hovered": "var(--sjs-semantic-red-background-500)",
    "--ctr-toolbox-group-header-margin-top": "var(--sjs-spacing-x1)",
    "--ctr-toolbox-group-header-margin-bottom": "var(--sjs-spacing-x1)",
    "--ctr-toolbox-separator-padding-left-compact": "var(--sjs-spacing-x2)",
    "--ctr-toolbox-separator-padding-right-compact": "var(--sjs-spacing-x2)",
    "--ctr-checkbox-gap": "var(--sjs-spacing-x1)",
    "--ctr-checkbox-description-text-margin-left": "var(--sjs-font-size-x4)",
    "--ctr-editor-layout-gap": "var(--sjs-spacing-x1)",
    "--ctr-expression-items-gap": "var(--sjs-spacing-x1)",
    "--ctr-expression-rows-gap": "var(--sjs-spacing-x2)",
    "--ctr-font-large-size": "var(--sjs-font-size-x4)",
    "--ctr-font-large-line-height": "var(--sjs-line-height-x5)",
    "--ctr-font-medium-size": "var(--sjs-font-size-x3)",
    "--ctr-font-medium-line-height": "var(--sjs-line-height-x4)",
    "--ctr-font-default-size": "var(--sjs-font-size-x2)",
    "--ctr-font-default-line-height": "var(--sjs-line-height-x3)",
    "--ctr-font-small-size": "var(--sjs-font-size-x150)",
    "--ctr-font-small-line-height": "var(--sjs-line-height-x2)",
    "--ctr-font-code-size": "var(--sjs-font-size-x2)",
    "--ctr-font-code-line-height": "var(--sjs-line-height-x3)",
    "--lbr-shadow-x-large-color": "var(--sjs-special-glow)",
    "--lbr-shadow-large-color": "var(--sjs-special-glow)",
    "--lbr-shadow-medium-color": "var(--sjs-special-glow)",
    "--lbr-shadow-small-color": "var(--sjs-special-shadow)",
    "--ctr-survey-checkboxes-item-corner-radius-floating": "var(--lbr-corner-radius-x1)",
    "--ctr-survey-radio-button-group-item-corner-radius-floating": "var(--lbr-corner-radius-x1)",
    "--ctr-survey-radio-button-group-item-background-color-floating": "var(--sjs-layer-1-background-500)",
    "--ctr-survey-radio-button-group-item-shadow-floating-1-offset-x": "var(--ctr-shadow-large-offset-x)",
    "--ctr-survey-radio-button-group-item-shadow-floating-1-offset-y": "var(--ctr-shadow-large-offset-y)",
    "--ctr-survey-radio-button-group-item-shadow-floating-1-blur": "var(--ctr-shadow-large-blur)",
    "--ctr-survey-radio-button-group-item-shadow-floating-1-spread": "var(--ctr-shadow-large-spread)",
    "--ctr-survey-radio-button-group-item-shadow-floating-1-color": "var(--ctr-shadow-large-color)",
    "--ctr-survey-radio-button-group-item-shadow-floating-2-offset-x": "var(--ctr-shadow-medium-offset-x)",
    "--ctr-survey-radio-button-group-item-shadow-floating-2-offset-y": "var(--ctr-shadow-medium-offset-y)",
    "--ctr-survey-radio-button-group-item-shadow-floating-2-blur": "var(--ctr-shadow-medium-blur)",
    "--ctr-survey-radio-button-group-item-shadow-floating-2-spread": "var(--ctr-shadow-medium-spread)",
    "--ctr-survey-radio-button-group-item-shadow-floating-2-color": "var(--ctr-shadow-medium-color)",
    "--ctr-survey-ranking-item-corner-radius-floating": "var(--lbr-corner-radius-x1)",
    "--ctr-survey-header-logo-placeholder-corner-radius": "var(--lbr-corner-radius-round)",
    "--ctr-survey-header-logo-placeholder-padding-left": "var(--lbr-spacing-x1)",
    "--ctr-survey-header-logo-placeholder-padding-right": "var(--lbr-spacing-x1)",
    "--ctr-survey-header-logo-placeholder-padding-top": "var(--lbr-spacing-x1)",
    "--ctr-survey-header-logo-placeholder-padding-bottom": "var(--lbr-spacing-x1)",
    "--ctr-survey-header-logo-placeholder-background-color-hovered": "var(--sjs-primary-background-10)",
    "--ctr-survey-header-logo-placeholder-icon-color-hovered": "var(--sjs-primary-background-500)",
    "--lbr-header-padding-left": "var(--lbr-spacing-x3)",
    "--ctr-survey-header-padding-right-logo-placeholder": "var(--lbr-spacing-x1)",
    "--lbr-header-padding-top": "var(--lbr-spacing-x3)",
    "--lbr-header-gap": "var(--lbr-spacing-x4)",
    "--ctr-survey-header-text-title-color-placeholder": "var(--sjs-layer-3-foreground-50)",
    "--ctr-survey-header-text-description-color-placeholder": "var(--sjs-layer-3-foreground-50)",
    "--ctr-survey-header-text-margin-left-mobile-logo-placeholder": "var(--lbr-spacing-x250)",
    "--lbr-question-panel-corner-radius": "var(--lbr-corner-radius-x05)",
    "--ctr-survey-question-panel-border-color-selected": "var(--sjs-secondary-background-500)",
    "--ctr-survey-question-panel-border-width-selected": "var(--lbr-stroke-x2)",
    "--lbr-question-panel-shadow-offset-x": "var(--lbr-shadow-small-offset-x)",
    "--lbr-question-panel-shadow-offset-y": "var(--lbr-shadow-small-offset-y)",
    "--lbr-question-panel-shadow-blur": "var(--lbr-shadow-small-blur)",
    "--lbr-question-panel-shadow-spread": "var(--lbr-shadow-small-spread)",
    "--lbr-question-panel-shadow-color": "var(--lbr-shadow-small-color)",
    "--ctr-survey-page-border-color-selected": "var(--sjs-secondary-background-500)",
    "--ctr-survey-page-border-width-selected": "var(--lbr-stroke-x2)",
    "--ctr-survey-page-corner-radius": "var(--lbr-corner-radius-x1)",
    "--ctr-survey-question-panel-border-color-hovered": "var(--sjs-secondary-background-25)",
    "--ctr-survey-question-panel-border-width-hovered": "var(--lbr-stroke-x2)",
    "--ctr-survey-question-panel-border-color-nested": "var(--sjs-border-25)",
    "--ctr-survey-question-panel-border-width-nested": "var(--lbr-stroke-x1)",
    "--ctr-survey-page-border-color-hovered": "var(--sjs-secondary-background-25)",
    "--ctr-survey-page-border-width-hovered": "var(--lbr-stroke-x2)",
    "--ctr-survey-panel-add-button-margin-top": "var(--lbr-spacing-x1)",
    "--ctr-survey-page-header-padding-vertical-with-button": "var(--sjs-spacing-x05)",
    "--lbr-error-panel-padding-left-small": "var(--sjs-spacing-x150)",
    "--lbr-error-panel-padding-right-small": "var(--lbr-spacing-x150)",
    "--ctr-survey-page-header-title-color-placeholder": "var(--sjs-layer-3-foreground-50)",
    "--ctr-survey-page-margin-left": "var(--lbr-spacing-x2)",
    "--ctr-survey-page-margin-right": "var(--lbr-spacing-x2)",
    "--ctr-survey-page-margin-top": "var(--lbr-spacing-x2)",
    "--ctr-survey-page-background-color-hovered": "var(--sjs-special-background)",
    "--ctr-survey-page-margin-bottom-new-page": "var(--lbr-spacing-x9)",
    "--lbr-question-panel-padding-left": "var(--lbr-spacing-x5)",
    "--lbr-question-panel-padding-right": "var(--lbr-spacing-x5)",
    "--lbr-question-panel-padding-top": "var(--lbr-spacing-x4)",
    "--ctr-survey-question-panel-padding-bottom-selected": "var(--lbr-spacing-x2)",
    "--lbr-page-header-padding-bottom": "var(--lbr-spacing-x3)",
    "--ctr-list-search-padding-left": "var(--sjs-spacing-x3)",
    "--ctr-list-search-border-color": "var(--sjs-border-25)",
    "--ctr-list-search-text-color-placeholder": "var(--sjs-layer-1-foreground-50)",
    "--ctr-property-grid-search-icon-width": "var(--sjs-font-size-x3)",
    "--ctr-property-grid-search-icon-height": "var(--sjs-font-size-x3)",
    "--ctr-toolbox-search-icon-width": "var(--sjs-font-size-x3)",
    "--ctr-toolbox-search-icon-height": "var(--sjs-font-size-x3)",
    "--ctr-button-padding-horizontal-contextual-button": "var(--sjs-spacing-x10)",
    "--ctr-surface-gap": "var(--sjs-spacing-x5)",
    "--ctr-expression-item-padding-left-button": "var(--sjs-spacing-x4)",
    "--ctr-expression-item-padding-right-button": "var(--sjs-spacing-x4)",
    "--ctr-toolbox-shadow-floating-1-offset-x": "var(--ctr-shadow-medium-offset-x)",
    "--ctr-toolbox-shadow-floating-2-offset-x": "var(--ctr-shadow-large-offset-x)",
    "--ctr-toolbox-shadow-floating-1-offset-y": "var(--ctr-shadow-medium-offset-y)",
    "--ctr-toolbox-shadow-floating-2-offset-y": "var(--ctr-shadow-large-offset-y)",
    "--ctr-toolbox-shadow-floating-1-blur": "var(--ctr-shadow-medium-blur)",
    "--ctr-toolbox-shadow-floating-2-blur": "var(--ctr-shadow-large-blur)",
    "--ctr-toolbox-shadow-floating-1-spread": "var(--ctr-shadow-medium-spread)",
    "--ctr-toolbox-shadow-floating-2-spread": "var(--ctr-shadow-large-spread)",
    "--ctr-toolbox-shadow-floating-2-color": "var(--ctr-shadow-large-color)",
    "--ctr-toolbox-shadow-floating-1-color": "var(--ctr-shadow-medium-color)",
    "--ctr-toolbox-item-padding-left-submenu": "var(--sjs-spacing-x2)",
    "--ctr-toolbox-item-padding-top-submenu": "var(--sjs-spacing-x150)",
    "--ctr-toolbox-item-padding-bottom-submenu": "var(--sjs-spacing-x150)",
    "--ctr-toolbox-submenu-group-margin-left": "var(--sjs-spacing-x150)",
    "--ctr-data-table-row-shadow-floating-1-offset-x": "var(--ctr-shadow-medium-offset-x)",
    "--ctr-data-table-row-shadow-floating-1-offset-y": "var(--ctr-shadow-medium-offset-y)",
    "--ctr-data-table-row-shadow-floating-1-blur": "var(--ctr-shadow-medium-blur)",
    "--ctr-data-table-row-shadow-floating-1-spread": "var(--ctr-shadow-medium-spread)",
    "--ctr-data-table-row-shadow-floating-1-color": "var(--ctr-shadow-medium-color)",
    "--ctr-data-table-row-shadow-floating-2-offset-x": "var(--ctr-shadow-large-offset-x)",
    "--ctr-data-table-row-shadow-floating-2-offset-y": "var(--ctr-shadow-large-offset-y)",
    "--ctr-data-table-row-shadow-floating-2-blur": "var(--ctr-shadow-large-blur)",
    "--ctr-data-table-row-shadow-floating-2-spread": "var(--ctr-shadow-large-spread)",
    "--ctr-data-table-row-shadow-floating-2-color": "var(--ctr-shadow-large-color)",
    "--ctr-toolbox-item-shadow-floating-1-offset-x": "var(--ctr-shadow-medium-offset-x)",
    "--ctr-toolbox-item-shadow-floating-1-offset-y": "var(--ctr-shadow-medium-offset-y)",
    "--ctr-toolbox-item-shadow-floating-1-blur": "var(--ctr-shadow-medium-blur)",
    "--ctr-toolbox-item-shadow-floating-1-spread": "var(--ctr-shadow-medium-spread)",
    "--ctr-toolbox-item-shadow-floating-1-color": "var(--ctr-shadow-medium-color)",
    "--ctr-toolbox-item-shadow-floating-2-offset-x": "var(--ctr-shadow-large-offset-x)",
    "--ctr-toolbox-item-shadow-floating-2-offset-y": "var(--ctr-shadow-large-offset-y)",
    "--ctr-toolbox-item-shadow-floating-2-blur": "var(--ctr-shadow-large-blur)",
    "--ctr-toolbox-item-shadow-floating-2-spread": "var(--ctr-shadow-large-spread)",
    "--ctr-toolbox-item-shadow-floating-2-color": "var(--ctr-shadow-large-color)",
    "--ctr-toolbox-item-shadow-hovered-1-offset-x": "var(--ctr-shadow-medium-offset-x)",
    "--ctr-toolbox-item-shadow-hovered-1-offset-y": "var(--ctr-shadow-medium-offset-y)",
    "--ctr-toolbox-item-shadow-hovered-1-blur": "var(--ctr-shadow-medium-blur)",
    "--ctr-toolbox-item-shadow-hovered-1-spread": "var(--ctr-shadow-medium-spread)",
    "--ctr-toolbox-item-shadow-pressed-offset-x": "var(--ctr-shadow-small-offset-x)",
    "--ctr-toolbox-item-shadow-pressed-offset-y": "var(--ctr-shadow-small-offset-y)",
    "--ctr-toolbox-item-shadow-pressed-blur": "var(--ctr-shadow-small-blur)",
    "--ctr-toolbox-item-shadow-pressed-spread": "var(--ctr-shadow-small-spread)",
    "--ctr-toolbox-item-shadow-hovered-2-offset-x": "var(--ctr-shadow-small-offset-x)",
    "--ctr-toolbox-item-shadow-hovered-2-offset-y": "var(--ctr-shadow-small-offset-y)",
    "--ctr-toolbox-item-shadow-hovered-2-blur": "var(--ctr-shadow-small-blur)",
    "--ctr-toolbox-item-shadow-hovered-2-spread": "var(--ctr-shadow-small-spread)",
    "--ctr-page-navigator-item-shadow-hovered-1-offset-x": "var(--ctr-shadow-medium-offset-x)",
    "--ctr-page-navigator-item-shadow-hovered-1-offset-y": "var(--ctr-shadow-medium-offset-y)",
    "--ctr-page-navigator-item-shadow-hovered-1-blur": "var(--ctr-shadow-medium-blur)",
    "--ctr-page-navigator-item-shadow-hovered-1-spread": "var(--ctr-shadow-medium-spread)",
    "--ctr-page-navigator-item-shadow-hovered-1-color": "var(--ctr-shadow-medium-color)",
    "--ctr-page-navigator-item-shadow-hovered-2-offset-x": "var(--ctr-shadow-small-offset-x)",
    "--ctr-page-navigator-item-shadow-hovered-2-offset-y": "var(--ctr-shadow-small-offset-y)",
    "--ctr-page-navigator-item-shadow-hovered-2-blur": "var(--ctr-shadow-small-blur)",
    "--ctr-page-navigator-item-shadow-hovered-2-spread": "var(--ctr-shadow-small-spread)",
    "--ctr-page-navigator-item-shadow-hovered-2-color": "var(--ctr-shadow-small-color)",
    "--ctr-button-shadow-offset-x": "var(--ctr-shadow-small-offset-x)",
    "--ctr-button-shadow-offset-y": "var(--ctr-shadow-small-offset-y)",
    "--ctr-button-shadow-blur": "var(--ctr-shadow-small-blur)",
    "--ctr-button-shadow-spread": "var(--ctr-shadow-small-spread)",
    "--ctr-button-shadow-color": "var(--ctr-shadow-small-color)",
    "--ctr-popup-menu-shadow-1-offset-x": "var(--ctr-shadow-medium-offset-x)",
    "--ctr-popup-menu-shadow-1-offset-y": "var(--ctr-shadow-medium-offset-y)",
    "--ctr-popup-menu-shadow-1-blur": "var(--ctr-shadow-medium-blur)",
    "--ctr-popup-menu-shadow-1-spread": "var(--ctr-shadow-medium-spread)",
    "--ctr-popup-menu-shadow-1-color": "var(--ctr-shadow-medium-color)",
    "--ctr-popup-menu-shadow-2-offset-x": "var(--ctr-shadow-large-offset-x)",
    "--ctr-popup-menu-shadow-2-offset-y": "var(--ctr-shadow-large-offset-y)",
    "--ctr-popup-menu-shadow-2-blur": "var(--ctr-shadow-large-blur)",
    "--ctr-popup-menu-shadow-2-spread": "var(--ctr-shadow-large-spread)",
    "--ctr-popup-menu-shadow-2-color": "var(--ctr-shadow-large-color)",
    "--ctr-popup-shadow-1-offset-x": "var(--ctr-shadow-medium-offset-x)",
    "--ctr-popup-shadow-1-offset-y": "var(--ctr-shadow-medium-offset-y)",
    "--ctr-popup-shadow-1-blur": "var(--ctr-shadow-medium-blur)",
    "--ctr-popup-shadow-1-spread": "var(--ctr-shadow-medium-spread)",
    "--ctr-popup-shadow-1-color": "var(--ctr-shadow-medium-color)",
    "--ctr-popup-shadow-2-offset-x": "var(--ctr-shadow-large-offset-x)",
    "--ctr-popup-shadow-2-offset-y": "var(--ctr-shadow-large-offset-y)",
    "--ctr-popup-shadow-2-blur": "var(--ctr-shadow-large-blur)",
    "--ctr-popup-shadow-2-spread": "var(--ctr-shadow-large-spread)",
    "--ctr-popup-shadow-2-color": "var(--ctr-shadow-large-color)",
    "--ctr-popup-shadow-3-offset-x": "var(--ctr-shadow-x-large-offset-x)",
    "--ctr-popup-shadow-3-offset-y": "var(--ctr-shadow-x-large-offset-y)",
    "--ctr-popup-shadow-3-blur": "var(--ctr-shadow-x-large-blur)",
    "--ctr-popup-shadow-3-spread": "var(--ctr-shadow-x-large-spread)",
    "--ctr-popup-shadow-3-color": "var(--ctr-shadow-x-large-color)",
    "--lbr-shadow-inner-color": "var(--sjs-special-shadow)",
    "--ctr-toolbox-item-background-color-submenu": "var(--sjs-layer-1-background-500)",
    "--ctr-toolbox-item-text-color-submenu": "var(--sjs-layer-2-foreground-100)",
    "--ctr-toolbox-min-width": "var(--sjs-size-x20)",
    "--ctr-font-default-line-height-all-caps": "var(--sjs-line-height-x2)",
    "--ctr-page-banner-background-color": "var(--sjs-secondary-background-500)",
    "--ctr-page-banner-text-color": "var(--sjs-secondary-foreground-100)",
    "--ctr-toolbox-group-padding-left-compact": "var(--sjs-spacing-x150)",
    "--ctr-toolbox-group-padding-right-compact": "var(--sjs-spacing-x150)",
    "--ctr-checkbox-button-check-mark-width": "var(--sjs-font-size-x2)",
    "--ctr-checkbox-button-check-mark-height": "var(--sjs-font-size-x2)",
    "--ctr-survey-question-panel-floating-toolbar-padding-left": "var(--lbr-spacing-x150)",
    "--ctr-survey-question-panel-floating-toolbar-padding-right": "var(--lbr-spacing-x150)",
    "--ctr-toolbox-item-icon-color-disabled": "var(--sjs-layer-1-foreground-100)",
    "--lbr-font-default-size": "var(--lbr-font-size-x2)",
    "--lbr-font-default-line-height": "var(--lbr-line-height-x3)",
    "--lbr-font-small-size": "var(--lbr-font-size-x150)",
    "--lbr-font-small-line-height": "var(--lbr-line-height-x2)",
    "--ctr-toolbox-item-background-color-floating": "var(--sjs-layer-1-background-500)",
    "--ctr-toolbox-item-margin-horizontal-no-text": "var(--sjs-spacing-x05)",
    "--ctr-toolbox-item-padding-left-no-text": "var(--sjs-spacing-x1)",
    "--ctr-toolbox-item-background-color-pressed": "var(--sjs-layer-1-background-400)",
    "--ctr-toolbox-submenu-group-margin-right": "var(--sjs-spacing-x150)",
    "--ctr-toolbox-submenu-group-margin-top": "var(--sjs-spacing-x150)",
    "--ctr-toolbox-submenu-group-margin-bottom": "var(--sjs-spacing-x150)",
    "--ctr-toolbox-submenu-group-margin-bottom-last": "var(--sjs-spacing-x150)",
    "--ctr-toolbox-submenu-group-border-width-bottom": "var(--sjs-stroke-x1)",
    "--ctr-toolbox-submenu-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-toolbox-submenu-corner-radius": "var(--sjs-corner-radius-x1)",
    "--ctr-toolbox-submenu-shadow-1-offset-x": "var(--ctr-shadow-medium-offset-x)",
    "--ctr-toolbox-submenu-shadow-1-offset-y": "var(--ctr-shadow-medium-offset-y)",
    "--ctr-toolbox-submenu-shadow-1-blur": "var(--ctr-shadow-medium-blur)",
    "--ctr-toolbox-submenu-shadow-1-spread": "var(--ctr-shadow-medium-spread)",
    "--ctr-toolbox-submenu-shadow-1-color": "var(--ctr-shadow-medium-color)",
    "--ctr-toolbox-submenu-shadow-2-offset-x": "var(--ctr-shadow-large-offset-x)",
    "--ctr-toolbox-submenu-shadow-2-offset-y": "var(--ctr-shadow-large-offset-y)",
    "--ctr-toolbox-submenu-shadow-2-blur": "var(--ctr-shadow-large-blur)",
    "--ctr-toolbox-submenu-shadow-2-spread": "var(--ctr-shadow-large-spread)",
    "--ctr-toolbox-submenu-shadow-2-color": "var(--ctr-shadow-large-color)",
    "--ctr-toolbox-submenu-margin-top": "var(--sjs-spacing-x1n)",
    "--ctr-list-item-background-color-selected-submenu": "var(--sjs-layer-1-background-400)",
    "--ctr-list-item-text-color-selected-submenu": "var(--sjs-layer-1-foreground-100)",
    "--ctr-list-item-icon-color-selected-submenu": "var(--sjs-primary-background-500)",
    "--ctr-list-item-submenu-arrow-color-selected-item-submenu": "var(--sjs-layer-1-foreground-75)",
    "--ctr-menu-item-padding-left-icon": "var(--sjs-spacing-x2)",
    "--ctr-menu-toolbar-button-padding-right": "var(--sjs-spacing-x1)",
    "--ctr-menu-toolbar-button-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-menu-item-padding-right": "var(--sjs-spacing-x3)",
    "--ctr-menu-item-padding-right-icon": "var(--sjs-spacing-x2)",
    "--ctr-menu-toolbar-button-padding-top-with-description": "var(--sjs-spacing-x05)",
    "--ctr-property-grid-header-padding-top-with-subtitle": "var(--sjs-spacing-x075)",
    "--ctr-property-grid-header-padding-bottom-with-subtitle": "var(--sjs-spacing-x075)",
    "--ctr-data-table-corner-radius": "var(--sjs-corner-radius-x075)",
    "--ctr-data-table-placeholder-corner-radius": "var(--sjs-corner-radius-x075)",
    "--ctr-list-item-label-icon-color-hovered": "var(--sjs-layer-1-foreground-50)",
    "--ctr-list-item-label-icon-color-disabled": "var(--sjs-layer-1-foreground-75)",
    "--ctr-data-table-form-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-data-table-form-border-color": "var(--sjs-border-10)",
    "--ctr-menu-toolbar-button-padding-left-with-description": "var(--sjs-spacing-x150)",
    "--ctr-menu-toolbar-button-padding-right-with-description": "var(--sjs-spacing-x150)",
    "--ctr-survey-page-drag-indicator-width": "var(--lbr-size-x3)",
    "--ctr-survey-page-drag-indicator-height": "var(--lbr-size-x2)",
    "--ctr-survey-resize-frame-grip-width": "var(--lbr-size-x150)",
    "--ctr-survey-resize-frame-grip-height": "var(--lbr-size-x150)",
    "--ctr-survey-resize-frame-margin": "var(--lbr-spacing-x1n)",
    "--ctr-surface-toolbar-padding-left": "var(--sjs-spacing-x2)",
    "--ctr-surface-toolbar-padding-right": "var(--sjs-spacing-x2)",
    "--ctr-surface-toolbar-padding-top": "var(--sjs-spacing-x2)",
    "--ctr-surface-toolbar-padding-bottom": "var(--sjs-spacing-x2)",
    "--ctr-surface-toolbar-gap": "var(--sjs-spacing-x1)",
    "--ctr-toggle-button-thumb-background-color-active": "var(--sjs-layer-3-foreground-75)",
    "--ctr-toggle-button-thumb-corner-radius": "var(--sjs-corner-radius-round)",
    "--ctr-toggle-button-corner-radius": "var(--sjs-corner-radius-round)",
    "--ctr-toggle-button-gap": "var(--sjs-spacing-x05)",
    "--ctr-toggle-button-padding-left": "var(--sjs-font-size-x075)",
    "--ctr-toggle-button-padding-right": "var(--sjs-font-size-x075)",
    "--ctr-toggle-button-padding-top": "var(--sjs-font-size-x075)",
    "--ctr-toggle-button-padding-bottom": "var(--sjs-font-size-x075)",
    "--ctr-toggle-button-background-color": "var(--sjs-layer-3-background-500)",
    "--ctr-toggle-button-border-color-focused": "var(--sjs-primary-background-500)",
    "--ctr-toggle-button-border-color": "var(--sjs-border-10)",
    "--ctr-toggle-button-thumb-width": "var(--sjs-font-size-x150)",
    "--ctr-toggle-button-thumb-height": "var(--sjs-font-size-x150)",
    "--ctr-toggle-button-background-color-checked": "var(--sjs-primary-background-500)",
    "--ctr-toggle-button-thumb-background-color-checked-focused": "var(--sjs-primary-background-500)",
    "--ctr-toggle-button-background-color-checked-focused": "var(--sjs-primary-foreground-100)",
    "--ctr-toggle-button-background-color-hovered": "var(--sjs-layer-3-background-400)",
    "--ctr-toggle-button-label-text-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-toggle-button-gap-label": "var(--sjs-spacing-x1)",
    "--ctr-toggle-button-thumb-background-color-checked": "var(--sjs-primary-foreground-100)",
    "--ctr-toggle-button-thumb-border-color": "var(--sjs-border-10)",
    "--ctr-toolbox-item-submenu-button-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-toolbox-item-submenu-button-background-color-hovered": "var(--sjs-primary-background-10)",
    "--ctr-toolbox-item-submenu-button-corner-radius": "var(--sjs-corner-radius-x05)",
    "--ctr-toolbox-item-submenu-button-padding-left": "var(--sjs-spacing-x05)",
    "--ctr-toolbox-item-submenu-button-padding-right": "var(--sjs-spacing-x05)",
    "--ctr-toolbox-item-submenu-button-padding-top": "var(--sjs-spacing-x05)",
    "--ctr-toolbox-item-submenu-button-padding-bottom": "var(--sjs-spacing-x05)",
    "--ctr-toolbox-item-submenu-button-icon-width": "var(--sjs-font-size-x2)",
    "--ctr-toolbox-item-submenu-button-icon-height": "var(--sjs-font-size-x2)",
    "--ctr-toolbox-item-submenu-button-icon-color": "var(--sjs-layer-1-foreground-50)",
    "--ctr-toolbox-item-submenu-button-margin-right": "var(--sjs-spacing-x1n)",
    "--ctr-survey-matching-item-padding-right-floating": "var(--lbr-spacing-x1)",
    "--ctr-survey-matching-item-padding-top-floating": "var(--lbr-spacing-x1)",
    "--ctr-survey-matching-item-padding-bottom-floating": "var(--lbr-spacing-x1)",
    "--ctr-survey-matching-item-corner-radius-floating": "var(--lbr-corner-radius-x2)",
    "--ctr-tooltip-corder-radius": "var(--sjs-corner-radius-x075)",
    "--ctr-tooltip-padding-left": "var(--sjs-spacing-x3)",
    "--ctr-tooltip-padding-right": "var(--sjs-spacing-x3)",
    "--ctr-tooltip-padding-top": "var(--sjs-spacing-x2)",
    "--ctr-tooltip-padding-bottom": "var(--sjs-spacing-x2)",
    "--ctr-tooltip-background-color": "var(--sjs-layer-1-background-500)",
    "--ctr-tooltip-shadow-1-offset-x": "var(--ctr-shadow-medium-offset-x)",
    "--ctr-tooltip-shadow-2-offset-x": "var(--ctr-shadow-small-offset-x)",
    "--ctr-tooltip-shadow-2-offset-y": "var(--ctr-shadow-small-offset-y)",
    "--ctr-tooltip-shadow-2-blur": "var(--ctr-shadow-small-blur)",
    "--ctr-tooltip-shadow-2-spread": "var(--ctr-shadow-small-spread)",
    "--ctr-tooltip-shadow-2-color": "var(--ctr-shadow-small-color)",
    "--ctr-tooltip-shadow-1-offset-y": "var(--ctr-shadow-medium-offset-y)",
    "--ctr-tooltip-pointer-height": "var(--sjs-size-x05)",
    "--ctr-tooltip-shadow-1-blur": "var(--ctr-shadow-medium-blur)",
    "--ctr-tooltip-shadow-1-spread": "var(--ctr-shadow-medium-spread)",
    "--ctr-tooltip-shadow-1-color": "var(--ctr-shadow-medium-color)",
    "--ctr-super-tooltip-corder-radius": "var(--sjs-corner-radius-x075)",
    "--ctr-menu-item-padding-bottom": "var(--sjs-spacing-x250)",
    "--ctr-menu-item-padding-top": "var(--sjs-spacing-x250)",
    "--ctr-scrollbar-padding-vertical-hovered": "var(--sjs-spacing-x025)",
    "--ctr-image-gallery-item-title-color": "var(--sjs-layer-1-foreground-100)",
    "--ctr-image-gallery-item-corner-radius": "var(--sjs-corner-radius-x075)",
    "--ctr-survey-radio-button-group-item-background-color-drop-spot": "var(--sjs-layer-1-background-400)",
    "--ctr-survey-radio-button-group-item-corner-radius-drop-spot": "var(--lbr-corner-radius-x1)",
    "--ctr-image-gallery-item-background-corner-radius-drop-spot": "var(--sjs-corner-radius-x1)",
    "--ctr-image-gallery-item-background-corner-radius-drop-spot-2": "var(--sjs-corner-radius-x1)",
    "--ctr-survey-checkboxes-item-corner-radius-drop-spot": "var(--lbr-corner-radius-x1)",
    "--ctr-survey-checkboxes-item-background-color-drop-spot": "var(--sjs-layer-2-background-500)",
    "--ctr-survey-ranking-item-background-color-drop-spot": "var(--sjs-layer-2-background-500)",
    "--ctr-survey-ranking-item-corner-radius-drop-spot": "var(--lbr-corner-radius-x1)",
    "--ctr-checkbox-description-text-margin-top": "var(--sjs-spacing-x1)",
    "--ctr-page-navigator-button-icon-color-checked": "var(--sjs-primary-background-500)",
    "--ctr-page-navigator-button-background-color-checked": "var(--sjs-primary-background-10)",
    "--ctr-surface-button-corner-radius": "var(--lbr-corner-radius-x05)",
    "--ctr-surface-button-padding-horizontal-contextual-button": "var(--lbr-spacing-x10)",
    "--ctr-surface-button-contextual-button-margin-horizontal": "var(--lbr-spacing-x1)",
    "--ctr-surface-button-padding-vertical": "var(--lbr-spacing-x2)",
    "--ctr-surface-button-contextual-button-icon-width": "var(--lbr-size-x3)",
    "--ctr-surface-button-contextual-button-icon-height": "var(--lbr-size-x3)",
    "--ctr-surface-button-contextual-button-margin-vertical": "var(--lbr-spacing-x1)",
    "--ctr-surface-button-contextual-button-corner-radius": "var(--lbr-corner-radius-x05)",
    "--ctr-surface-button-contextual-button-padding-left": "var(--lbr-spacing-x1)",
    "--ctr-surface-button-contextual-button-padding-right": "var(--lbr-spacing-x1)",
    "--ctr-surface-button-contextual-button-padding-top": "var(--lbr-spacing-x1)",
    "--ctr-surface-button-contextual-button-padding-bottom": "var(--lbr-spacing-x1)",
    "--ctr-preview-pager-item-corner-radius": "var(--sjs-corner-radius-x05)",
    "--ctr-preview-pager-item-padding-left": "var(--sjs-spacing-x1)",
    "--ctr-preview-pager-item-padding-left-with-text": "var(--sjs-spacing-x1)",
    "--ctr-preview-pager-item-padding-right": "var(--sjs-spacing-x1)",
    "--ctr-preview-pager-item-padding-right-with-text": "var(--sjs-spacing-x1)",
    "--ctr-preview-pager-item-padding-top": "var(--sjs-spacing-x1)",
    "--ctr-preview-pager-item-padding-top-with-text": "var(--sjs-spacing-x1)",
    "--ctr-preview-pager-item-padding-bottom": "var(--sjs-spacing-x1)",
    "--ctr-preview-pager-item-padding-bottom-with-text": "var(--sjs-spacing-x1)",
    "--ctr-preview-pager-item-icon-container-width": "var(--sjs-font-size-x2)",
    "--ctr-preview-pager-item-icon-container-height": "var(--sjs-font-size-x2)",
    "--ctr-editor-border-color-highlighted": "var(--sjs-layer-1-foreground-75)",
    "--ctr-editor-border-width-highlighed": "var(--sjs-stroke-x2)",
    "--ctr-survey-page-content-margin-top-no-header": "var(--lbr-spacing-x1)",
    "--ctr-survey-question-panel-toolbar-item-background-color-pressed": "var(--sjs-layer-1-background-400)",
    "--ctr-survey-page-toolbar-item-background-color-pressed": "var(--sjs-primary-background-10)",
    "--ctr-survey-page-toolbar-item-icon-color-disabled": "var(--sjs-layer-3-foreground-50)",
    "--ctr-survey-page-toolbar-item-icon-color": "var(--sjs-primary-background-500)",
    "--ctr-survey-page-margin-left-with-toolbar": "var(--sjs-spacing-x925)",
    "--ctr-survey-page-margin-right-with-toolbar": "var(--sjs-spacing-x925)",
    "--ctr-expression-item-padding-left-icon-only": "var(--sjs-spacing-x1)",
    "--ctr-expression-item-padding-right-icon-only": "var(--sjs-spacing-x1)",
    "--ctr-expression-item-icon-width": "var(--sjs-font-size-x3)",
    "--ctr-expression-item-icon-height": "var(--sjs-font-size-x3)",
    "--ctr-expression-item-icon-color": "var(--sjs-semantic-red-background-500)",
    "--ctr-expression-item-background-color-remove-button-hovered": "var(--sjs-semantic-red-background-10)",
    "--ctr-collapse-button-margin-top": "var(--sjs-spacing-x05n)",
    "--ctr-collapse-button-margin-right": "var(--sjs-spacing-x2)"
  }
};
export default Theme;
export const Default = Theme;