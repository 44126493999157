import setupAxiosWithCredentials from "../utils/setupAxiosWithCredentials";
import { axiosWithCredentials } from "../api/axios";

class BASE_API {
    constructor(base) {
        this.base_url = base;
    }

    async save(payload) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.post(this.base_url, payload);
            return data;
        } finally {
            removeInterceptors();
        }
    }

    get = async (id) => {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            let data 
            if (id) data = await axiosWithCredentials.get(this.base_url + '/' + id);
            if (!id) data = await axiosWithCredentials.get(this.base_url)
            return data;
        } finally {
            removeInterceptors();
        }
    }

    edit = async (id, data) => {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const response = await axiosWithCredentials.put(this.base_url  + (id ? `/${id}` : ''), data);
            return response;
        } finally {
            removeInterceptors();
        }
    }

    async delete(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.delete(this.base_url + '/' + id);
            return data;
        } finally {
            removeInterceptors();
        }
    }
}

export default BASE_API;

