import React, { useState } from 'react';
import { Button, HStack } from '@arcault-ui/shared-components';

const PhoneControls = ({ incomingCall, makeCall, acceptCall, declineCall, hangupCall }) => {
  

  return (
    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      {incomingCall ? (
        <HStack>
          <Button type="primary" onClick={acceptCall} >
            Accept
          </Button>
          <Button type="outline" onClick={declineCall}>
            Decline
          </Button>
        </HStack>
      ) : (
        <HStack variant="spread">
          <Button type="outline" onClick={hangupCall}>
            Hang Up
          </Button>
          <Button type="primary" onClick={makeCall} style={{ marginRight: '10px' }}>
            Call
          </Button>
        </HStack>
      )}
    </div>
  );
};

export default PhoneControls;
